import React from "react";
import { authRoles } from "app/auth";

export const AdvertisersConfig = {
  auth: authRoles.admin,
  routes: [
    {
      path: "/advertisers/:id",
      component: React.lazy(() => import("./advertiser/Advertiser")),
      name: "Edit Advertiser",
    },
    {
      path: "/advertisers",
      component: React.lazy(() => import("./advertisers/Advertisers")),
      name: "Advertisers",
    },
  ],
};
