import * as Actions from "./store/actions";

import React, { Component } from "react";
import TextEditors from "app/views/Editors/GeneralFilesEditor/TextEditors";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

const GeneralHOC = (fileTitle, fileid) => {
  class GeneralFile extends Component {
    constructor(props) {
      super(props);
      this.state = { defaultCountry: false, loading: true };
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    async componentDidMount() {
      await this.props.dispatch(Actions.getFile(fileid));

      this.setState({
        loading: false,
      });

      this.props.dispatch(Actions.fetchCountries());
      //this.props.fetchCountries();
    }
    loading = () => (
      <div className="animated fadeIn pt-1 text-center">Loading...</div>
    );
    handleSubmit(text, countrycode) {
      this.props.dispatch(
        Actions.saveFile(
          fileid,
          text,
          this.state.defaultCountry === countrycode ? false : countrycode
        )
      );
    }
    setDefaultCountry = () => {
      this.props.countries.forEach((country) => {
        if (country.default === true) {
          this.setState({
            defaultCountry: country.countrycode,
          });
        }
      });
    };
    render() {
      if (
        this.props.countries !== false &&
        this.state.defaultCountry === false
      ) {
        this.setDefaultCountry();
      }
      if (
        this.props.countries === false ||
        this.state.defaultCountry === false ||
        this.state.loading
      ) {
        return (
          <div className="animated fadeIn pt-1 text-center">
            <div className="sk-spinner sk-spinner-pulse"></div>
          </div>
        );
      }

      let contents = {};

      this.props.countries.forEach((item) => {
        if (item.countrycode === this.state.defaultCountry) {
          contents[item.countrycode] = this.props.general.files.content;
        } else {
          if (
            this.props.general.files[item.countrycode + "_content"] ===
            undefined
          ) {
            contents[item.countrycode] = "";
          } else {
            contents[item.countrycode] =
              this.props.general.files[item.countrycode + "_content"];
          }
        }
      });

      return (
        <div className="animated fadeIn">
          <TextEditors
            contents={contents}
            title={fileTitle}
            handleSubmit={this.handleSubmit}
            countries={this.props.countries}
            defaultCountry={this.state.defaultCountry}
          />
        </div>
      );
    }
  }

  // function mapDispatchToProps(dispatch) {
  //   return bindActionCreators(Actions, dispatch);
  // }

  return withReducer("general", reducer)(connect(mapStateToProps)(GeneralFile));
};

const mapStateToProps = (state, ownProps) => {
  return {
    general: state.general,
    countries: state.general.countries.countries,
  };
};

export default GeneralHOC;
