/**
 * Authorization Roles
 */
export const authRoles = {
  admin: ["admin"],
  staff: ["admin", "staff"],
  user: ["admin", "staff", "user"],
  onlyGuest: [],

  usersViewer: ["users-viewer"],
  usersPersonal: ["users-viewer", "users-personal"],
  usersManager: ["users-viewer", "users-manager"],
  rewardsViewer: ["rewards-viewer"],
  revenuesViewer: ["revenues-viewer"],
  profitViewer: ["profit-viewer"],
  gamesViewer: ["games-viewer"],
  gamesBooster: ["games-viewer", "games-booster"],
  gamesActivator: ["games-viewer", "games-activator"],
  gamesMultiplier: ["games-viewer", "games-multiplier"],
  bonusViewer: ["bonus-viewer"],
  contestsViewer: ["contests-viewer"],
  contestsEditor: ["contests-editor"],
  dailyQuestsViewer: ["dailyQuests-viewer"],
  dailyQuestsEditor: ["dailyQuests-editor"],
  generalViewer: ["general-viewer"],
  notificationsViewer: ["notifications-viewer"],
  fraudpanelViewer: ["fraudpanel-viewer"],
  breakdownStatsViewer: ["breakdownstats-viewer"],
  fraudalyticsViewer: ["fraudalytics-viewer"],
  breakdownStatsEventsViewer: ["breakdownstats-events-viewer"],
  installViewer: ["install-viewer"],
  referalsViewer: ["referals-viewer"],
  contentRewardViewer: ["contentreward-viewer"],
  gaidBlockViewer: ["gaidBlock-viewer"],
};

export const allPermissions = [
  "admin",
  "staff",
  "users-viewer",
  "users-personal",
  "users-manager",
  "rewards-viewer",
  "revenues-viewer",
  "games-viewer",
  "games-booster",
  "games-activator",
  "games-multiplier",
  "bonus-viewer",
  "contests-viewer",
  "contests-editor",
  "dailyQuests-viewer",
  "dailyQuests-editor",
  "general-viewer",
  "notifications-viewer",
  "fraudpanel-viewer",
  "breakdownstats-viewer",
  "fraudalytics-viewer",
  "breakdownstats-events-viewer",
  "install-viewer",
  "referals-viewer",
  "contentreward-viewer",
  "profit-viewer",
];

export const userRoles = [
  { name: "Admin", permissions: ["admin"] },
  {
    name: "Senior Account manager",
    permissions: [
      "staff",
      ...authRoles.gamesMultiplier,
      ...authRoles.gamesActivator,
      ...authRoles.gamesBooster,
      ...authRoles.contestsViewer,
      ...authRoles.contestsEditor,
      ...authRoles.dailyQuestsViewer,
      ...authRoles.dailyQuestsEditor,
      ...authRoles.notificationsViewer,
      ...authRoles.fraudpanelViewer,
      ...authRoles.revenuesViewer,
      ...authRoles.fraudalyticsViewer,
      ...authRoles.rewardsViewer,
      ...authRoles.usersViewer,
      ...authRoles.breakdownStatsViewer,
      ...authRoles.breakdownStatsEventsViewer,
      ...authRoles.gaidBlockViewer,
    ],
  },
  {
    name: "Account manager",
    permissions: [
      "staff",
      ...authRoles.gamesViewer,
      ...authRoles.fraudpanelViewer,
      ...authRoles.revenuesViewer,
      ...authRoles.fraudalyticsViewer,
      ...authRoles.rewardsViewer,
      ...authRoles.usersViewer,
      ...authRoles.breakdownStatsViewer,
      ...authRoles.breakdownStatsEventsViewer,
      ...authRoles.gaidBlockViewer,
    ],
  },
  {
    name: "Customer Support",
    permissions: [
      "staff",
      ...authRoles.usersManager,
      ...authRoles.rewardsViewer,
      ...authRoles.fraudpanelViewer,
      ...authRoles.installViewer,
      ...authRoles.fraudalyticsViewer,
      ...authRoles.referalsViewer,
      ...authRoles.contestsViewer,
      ...authRoles.contentRewardViewer,
      ...authRoles.gaidBlockViewer,
    ],
  },
];

export const allClientsPermissions = [
  "staff",
  "users-viewer",
  "breakdownstats-viewer",
  "breakdownstats-events-viewer",
  "breakdownstats-purchases-viewer",
  "breakdownstats-retention-viewer",
  "breakdownstats-osversions-viewer",
  "breakdownstats-bydate-viewer",
];

export const clientsRoles = [
  {
    name: "Advertiser",
    permissions: ["staff", ...authRoles.usersViewer],
  },
];

export default authRoles;
