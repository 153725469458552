import React from "react";
import { authRoles } from "app/auth";

export const ContentRewardConfig = {
  auth: [...authRoles.admin, ...authRoles.contentRewardViewer],
  routes: [
    {
      path: "/contentreward",
      component: React.lazy(() => import("./contentReward/contentReward")),
      name: "Content Reward",
    },
  ],
};
