import Install from "./Install";
import { authRoles } from "app/auth";

export const InstallConfig = {
  auth: [...authRoles.admin, ...authRoles.installViewer],
  routes: [
    {
      path: "/install",
      component: Install,
      name: "Install",
    },
  ],
};
