import * as Actions from "../actions";

import moment from "moment";

const initialState = {
  loading: false,
  data: {},
  gamesforselect: [],
  eventsforselect: [],
  countriesforselect: [],
  startDate: moment().subtract(7, "d"),
  endDate: moment(),
};

const agebucketsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_DATERANGE: {
      const {
        payload: { startDate, endDate },
      } = action;
      return {
        ...state,
        startDate,
        endDate,
      };
    }
    case Actions.GET_GAMES_FOR_SELECT: {
      const { payload } = action;
      return {
        ...state,
        gamesforselect: [...payload.data],
      };
    }
    case Actions.GET_GAMES_EVENTS_FOR_SELECT: {
      const { payload } = action;
      return {
        ...state,
        eventsforselect: [...payload.data],
      };
    }
    case Actions.GET_COUNTRIES_FOR_SELECT: {
      const { payload } = action;
      return {
        ...state,
        countriesforselect: [...payload.data],
      };
    }
    case Actions.GET_BUCKETS: {
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
        },
      };
    }
    case Actions.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default agebucketsReducer;
