import Isp from "./Isp";
import { authRoles } from "app/auth";

export const IspConfig = {
  auth: [...authRoles.admin],
  routes: [
    {
      path: "/isp",
      component: Isp,
      name: "ISP",
    },
  ],
};
