import { combineReducers } from "redux";
import filter from "./filter.reducer";
import revenues from "./revenues.reducer";

const reducer = combineReducers({
  revenues,
  filter,
});

export default reducer;
