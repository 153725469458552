import React from "react";
import { authRoles } from "app/auth";
export const RewardsConfig = {
  auth: [...authRoles.admin, ...authRoles.rewardsViewer],
  routes: [
    {
      path: "/rewards/all",
      component: React.lazy(() => import("./allrewards/AllRewards")),
      name: "All Rewards",
    },
    {
      path: "/rewards/manage",
      component: React.lazy(() => import("./managerewards/ManageRewards")),
      name: "Manage Rewards",
    },
    {
      path: "/rewards/requested/:reward_id",
      component: React.lazy(() => import("./reward/Reward")),
      name: "More Info",
    },
    {
      path: "/rewards/requested",
      component: React.lazy(() =>
        import("./requestedrewards/RequestedRewards")
      ),
      name: "Requested Rewards",
    },
  ],
};
