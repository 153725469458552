import React from "react";
import { authRoles } from "app/auth";

export const GaidUserInfoConfig = {
  auth: [...authRoles.admin, ...authRoles.gaidBlockViewer],
  routes: [
    {
      path: "/gaid-user-info",
      component: React.lazy(() => import("./gaiduserinfo/gaiduserinfo")),
      name: "GAID User Info",
    },
  ],
};
