import * as Actions from "../../actions/tapchamps/index";

const initialState = {
  activeUsers: localStorage.getItem("activeUsers") === "1",
};

const message = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CFG_ACTIVEUSERS: {
      localStorage.setItem("activeUsers", action.payload === true ? "1" : "0");
      return {
        ...state,
        activeUsers: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default message;
