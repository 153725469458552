import React from "react";
import { authRoles } from "app/auth";

export const ContestsConfig = {
  auth: [...authRoles.admin, ...authRoles.contestsViewer],
  routes: [
    {
      path: "/contests/new",
      component: React.lazy(() => import("./contest/contest/Contest")),
      name: "Contest",
    },
    {
      path: "/contests/:contest_id",
      component: React.lazy(() => import("./contest/ContestTabs")),
      name: "Contest",
    },
    {
      path: "/contests",
      component: React.lazy(() => import("./contests/Contests")),
      name: "Contests",
    },
  ],
};
