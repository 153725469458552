import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, Spinner } from "reactstrap";
import React, { useRef } from "react";

import { Link } from "react-router-dom";
import moment from "moment";

function dateFormat(col) {
  if (col === undefined) {
    return null;
  }
  return moment(col).format("lll");
}

const ReferalsTable = ({
  onSortChange,
  onSearchChange,
  onPageChange,
  onSizePerPageList,
  onDeleteRow,
  data,
  loading,
  totalDataSize,
  sizePerPage,
  currentPage,
  role,
  filterDays,
  filterDaysClick,
  onFilterChange,
}) => {
  const created_at = useRef();
  const count = useRef();
  const success = useRef();
  const ratio = useRef();
  const parent_username = useRef();
  const invited_users = useRef();

  //
  // actions
  //
  const handlerClickCleanFiltered = (e) => {
    created_at.current.cleanFiltered();
    count.current.cleanFiltered();
    success.current.cleanFiltered();
    ratio.current.cleanFiltered();
    parent_username.current.cleanFiltered();
    invited_users.current.cleanFiltered();
  };

  const invitedUsersFormat = (cell, row) => {
    if (!cell) {
      return "";
    }
    let users = cell.map((item, index) => {
      if (cell.length - 1 === index) {
        return (
          <span key={index}>
            <Link to={"/users/" + item._id}>{item.username}</Link>
          </span>
        );
      } else {
        return (
          <span key={index}>
            <Link to={"/users/" + item._id}>{item.username}</Link>
            {", "}
          </span>
        );
      }
    });
    return <>{users}</>;
  };

  const parentUsernameFormat = (cell, row) => {
    return <Link to={"/users/" + row.id}>{cell}</Link>;
  };

  return (
    <>
      <Button
        onClick={handlerClickCleanFiltered}
        size="sm"
        outline
        className="mb-1 mr-1"
      >
        clear filters
      </Button>
      <Button
        color="primary"
        onClick={() => filterDaysClick(1)}
        size="sm"
        outline={!(filterDays === 1)}
        className="mb-1 mr-1"
      >
        Last 24h
      </Button>
      <Button
        color="primary"
        onClick={() => filterDaysClick(2)}
        size="sm"
        outline={!(filterDays === 2)}
        className="mb-1 mr-1"
      >
        Last 2 days
      </Button>{" "}
      <Button
        color="primary"
        onClick={() => filterDaysClick(3)}
        size="sm"
        outline={!(filterDays === 3)}
        className="mb-1 mr-1"
      >
        Last 3 days
      </Button>{" "}
      <Button
        color="primary"
        onClick={() => filterDaysClick(4)}
        size="sm"
        outline={!(filterDays === 4)}
        className="mb-1 mr-1"
      >
        Last 4 days
      </Button>{" "}
      <Button
        color="primary"
        onClick={() => filterDaysClick(7)}
        size="sm"
        outline={!(filterDays === 7)}
        className="mb-1 mr-1"
      >
        Last 7 days
      </Button>
      {loading === true && (
        <Spinner className="ml-2" color="primary" size="sm" />
      )}
      <BootstrapTable
        trClassName="customBootstrapTable"
        data={loading ? [] : data}
        remote={true}
        pagination={true}
        striped
        fetchInfo={{ dataTotalSize: totalDataSize }}
        options={{
          defaultSortName: "latest_referral_date",
          defaultSortOrder: "desc",
          onSearchChange: onSearchChange,
          clearSearch: true,
          onFilterChange: onFilterChange,
          onDeleteRow: onDeleteRow,
          onSortChange: onSortChange,
          sizePerPage: sizePerPage,
          onPageChange: onPageChange,
          sizePerPageList: [10, 20, 50, 100],
          page: currentPage,
          onSizePerPageList: onSizePerPageList,
        }}
      >
        <TableHeaderColumn dataField="id" isKey hidden>
          ID
        </TableHeaderColumn>

        <TableHeaderColumn
          editable={false}
          ref={parent_username}
          width="150px"
          dataField="username"
          dataFormat={parentUsernameFormat}
          filter={{ type: "TextFilter", delay: 2000 }}
          dataSort={true}
        >
          Parent User
        </TableHeaderColumn>

        <TableHeaderColumn
          editable={false}
          ref={invited_users}
          //width="300px"
          dataField="invited_users:username"
          dataFormat={invitedUsersFormat}
          tdStyle={{ whiteSpace: "normal" }}
          filter={{ type: "TextFilter", delay: 2000 }}
        >
          Invited Users
        </TableHeaderColumn>
        <TableHeaderColumn
          editable={false}
          ref={count}
          width="80px"
          dataField="count"
          dataSort={true}
          filter={{
            type: "NumberFilter",
            delay: 1000,
            numberComparators: ["=", ">", "<="],
          }}
        >
          Count of Invited Users
        </TableHeaderColumn>
        <TableHeaderColumn
          editable={false}
          ref={success}
          width="80px"
          dataSort={true}
          dataField="success"
          filter={{
            type: "NumberFilter",
            delay: 1000,
            numberComparators: ["=", ">", "<="],
          }}
        >
          Success
        </TableHeaderColumn>
        <TableHeaderColumn
          editable={false}
          ref={ratio}
          width="80px"
          dataSort={true}
          dataField="ratio"
          filter={{
            type: "NumberFilter",
            delay: 1000,
            numberComparators: ["=", ">", "<="],
          }}
        >
          Ratio
        </TableHeaderColumn>
        <TableHeaderColumn
          editable={false}
          ref={created_at}
          width="160px"
          dataField="latest_referral_date"
          dataSort={true}
          dataFormat={dateFormat}
        >
          Latest Referal Date
        </TableHeaderColumn>
      </BootstrapTable>
    </>
  );
};

export default ReferalsTable;
