import * as Actions from "../actions";

import moment from "moment";

const initialState = {
  current: {
    startDate: moment().subtract(7, "d"),
    endDate: moment(),
    groupBy: 0,
    filter: {
      username: null,
      game: null,
    },
  },
  groupByOptions: {
    0: { key: 0, value: "Game" },
    1: { key: 1, value: "Game Category" },
    2: { key: 2, value: "Country" },
  },
};

const filterReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_GROUPBY: {
      const { groupBy } = action.payload;
      return {
        ...state,
        current: {
          ...state.current,
          groupBy,
        },
      };
    }
    case Actions.SET_DATERANGE: {
      const { startDate, endDate } = action.payload;
      return {
        ...state,
        current: {
          ...state.current,
          startDate,
          endDate,
        },
      };
    }
    case Actions.SET_DATEGROUP: {
      const { dateGroup } = action.payload;
      return {
        ...state,
        current: {
          ...state.current,
          dateGroup,
        },
      };
    }
    case Actions.SET_USERNAME_FILTER: {
      const { username } = action.payload;
      return {
        ...state,
        current: {
          ...state.current,
          filter: {
            ...state.filter,
            username,
          },
        },
      };
    }
    case Actions.SET_GAME_FILTER: {
      const { game } = action.payload;
      return {
        ...state,
        current: {
          ...state.current,
          filter: {
            ...state.filter,
            game,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default filterReducer;
