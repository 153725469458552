import React from "react";
import { authRoles } from "app/auth";

export const RafflesConfig = {
  auth: [...authRoles.admin, ...authRoles.contestsViewer],
  routes: [
    {
      path: "/raffles/new",
      component: React.lazy(() => import("./raffle/raffle/Raffle")),
      name: "Raffle",
    },
    {
      path: "/raffles/:raffle_id",
      component: React.lazy(() => import("./raffle/RaffleTabs")),
      name: "Raffle",
    },
    {
      path: "/raffles",
      component: React.lazy(() => import("./raffles/Raffles")),
      name: "Raffles",
    },
  ],
};
