import React from "react";
import { authRoles } from "app/auth";
export const CampaignEmailReportsConfig = {
  auth: [...authRoles.admin, ...authRoles.gamesViewer],
  routes: [
    {
      path: "/campaignemailreport/new",
      component: React.lazy(() =>
        import("./campaignemailreport/CampaignemailreportNew")
      ),
      name: "New Email Report",
    },
    {
      path: "/campaignemailreport/:id",
      component: React.lazy(() =>
        import("./campaignemailreport/CampaignemailreportEdit")
      ),
      name: "Edit Email Report",
    },
    {
      path: "/campaignemailreports",
      component: React.lazy(() =>
        import("./campaignemailreports/Campaignemailreports")
      ),
      name: "Reports",
    },
  ],
};
