import * as Actions from "../actions";

import { sortBy } from "lodash";
import moment from "moment";

const initialState = {
  loading: false,
  data: {},
  averageUnits: [],
  mediaforselect: [],
  startDate: moment().subtract(9, "d"),
  endDate: moment().subtract(2, "d"),
};

const profitReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_DATERANGE: {
      const {
        payload: { startDate, endDate },
      } = action;
      return {
        ...state,
        startDate,
        endDate,
      };
    }
    case Actions.GET_MEDIA_FOR_SELECT: {
      const { payload } = action;
      return {
        ...state,
        mediaforselect: [...payload.data],
      };
    }
    case Actions.GET_PROFIT: {
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
        },
      };
    }
    case Actions.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.SET_AVG_UNITS: {
      const units = sortBy(action.payload, (u) => u.created_at_date.value);
      return {
        ...state,
        loading: false,
        averageUnits: [...units],
      };
    }
    default: {
      return state;
    }
  }
};

export default profitReducer;
