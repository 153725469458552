import * as Actions from "../actions";

const initialState = {
  tabid: 1,
  stats: {
    banned_users_today: "0",
    banned_users_previous_day: "0",
    banned_users_last_7_days: "0",
    banned_users_this_month: "0",
    banned_users_last_month: "0",
  },

  usersTable: {
    users_registred_today: [],
    users_registred_yesterday: [],
    users_registred_last7days: [],
    users_registred_thismonth: [],
    users_registred_lastmonth: [],
  },

  rewardsTable: {
    resolution_reward: [],
  },
  isLoading: false,
};

const fraudalyticsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_FRAUDALYTICS_TAB: {
      const { payload } = action;
      return {
        ...state,
        tabid: payload.tabid,
      };
    }
    case Actions.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case Actions.GET_REQUESTS: {
      let forresolution_reward = [];
      action.payload.forEach((res) => {
        forresolution_reward.push({
          resolution: res.resolution,
          payment: res.value,
        });
      });
      return {
        ...state,
        rewardsTable: {
          resolution_reward: forresolution_reward,
        },
      };
    }
    case Actions.GET_USERS: {
      const users_registred_today =
        action.payload.today !== undefined ? action.payload.today : "none";
      const users_registred_yesterday =
        action.payload.yesterday !== undefined
          ? action.payload.yesterday
          : "none";
      const users_registred_last7days =
        action.payload.last7days !== undefined
          ? action.payload.last7days
          : "none";
      const users_registred_thismonth =
        action.payload.thismonth !== undefined
          ? action.payload.thismonth
          : "none";
      const users_registred_lastmonth =
        action.payload.lastmonth !== undefined
          ? action.payload.lastmonth
          : "none";

      return {
        ...state,
        usersTable: {
          users_registred_today: users_registred_today,
          users_registred_yesterday: users_registred_yesterday,
          users_registred_last7days: users_registred_last7days,
          users_registred_thismonth: users_registred_thismonth,
          users_registred_lastmonth: users_registred_lastmonth,
        },
      };
    }
    case Actions.GET_STATS: {
      const banned_users_today =
        action.payload.banned_users_today !== undefined
          ? action.payload.banned_users_today
          : 0;
      const banned_users_previous_day =
        action.payload.banned_users_previous_day !== undefined
          ? action.payload.banned_users_previous_day
          : 0;
      const banned_users_last_7_days =
        action.payload.banned_users_last_7_days !== undefined
          ? action.payload.banned_users_last_7_days
          : 0;
      const banned_users_this_month =
        action.payload.banned_users_this_month !== undefined
          ? action.payload.banned_users_this_month
          : 0;
      const banned_users_last_month =
        action.payload.banned_users_last_month !== undefined
          ? action.payload.banned_users_last_month
          : 0;

      return {
        ...state,
        stats: {
          banned_users_today: banned_users_today,
          banned_users_previous_day: banned_users_previous_day,
          banned_users_last_7_days: banned_users_last_7_days,
          banned_users_this_month: banned_users_this_month,
          banned_users_last_month: banned_users_last_month,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default fraudalyticsReducer;
