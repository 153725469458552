import React from "react";
import { Table } from "reactstrap";

const CollapsibleRow = ({ details }) => {
  return (
    <Table responsive size="sm" style={{ textAlign: "right" }} hover>
      <thead>
        <tr>
          <th width="100px" style={{ textAlign: "left" }}>
            Media Source
          </th>
          <th width="200px">Revenue ($)</th>
          <th width="120px">Revenue (%)</th>
          <th width="180px">Cost ($)</th>
          <th width="100px">Cost (%)</th>
          <th width="220px">Cost Rewards ($)</th>
          <th width="140px">Cost Rewards (%)</th>
          <th width="180px">Profit ($)</th>
          <th width="100px">Profit (%)</th>
        </tr>
      </thead>
      <tbody>
        {details.map((item, index) => (
          <tr key={index}>
            <td width="100px" style={{ textAlign: "left", fontWeight: "bold" }}>
              {item.bracket2}
            </td>
            <td width="200px">{item.revenue.toFixed(1)}</td>
            <td width="120px">{item.revenuePerc.toFixed(1)}</td>
            <td width="180px">{item.cost.toFixed(1)}</td>
            <td width="100px">{item.costPerc.toFixed(1)}</td>
            <td width="220px">{item.rewardscost.toFixed(1)}</td>
            <td width="140px">{item.rewardscostPerc.toFixed(1)}</td>
            <td width="180px">{item.profit.toFixed(1)}</td>
            <td width="100px">{item.profitPerc.toFixed(1)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CollapsibleRow;
