// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

import App from "./app/App";
import React from "react";
import ReactDOM from "react-dom";
import history from "@history";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4af57696388d298d07fee01311887bbf@o4505323734499328.ingest.sentry.io/4506098364055552",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
      tracePropagationTargets: ["localhost", "https://admin.tapchamps.com/.*"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
