const tablebaseState = {
  loading: false,
  table: {
    totalDataSize: 0,
    sizePerPage: 10,
    currentPage: 1,
    filter: {},
    sort: {},
  },
  data: {},
};

const tablebaseReducer = function (state = tablebaseState, action) {
  switch (action.payload.subtype) {
    default: {
      return state;
    }
  }
};

const initialState = {
  countries: tablebaseState,
};

const tableReducer = function (state = initialState, action) {
  switch (action.type) {
    case "countries":
      return {
        ...state,
        countries: tablebaseReducer(state.countries, action),
      };
    default: {
      return state;
    }
  }
};

export default tableReducer;
