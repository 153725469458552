import * as Actions from "./store/actions";
import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Button,
  Spinner,
} from "reactstrap";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

class Fraudalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateUsersResToday: [],
      stateUsersResYesterday: [],
      stateUsersResLast7days: [],
      stateUsersResThisMonth: [],
      stateUsersResLastMonth: [],
      stateUsersModelToday: [],
      stateUsersModelYesterday: [],
      stateUsersModelLast7days: [],
      stateUsersModelThisMonth: [],
      stateUsersModelLastMonth: [],
      stateResolutionReward: [],
      selectedDay: "today",
    };
  }
  componentDidMount() {
    this.props.getStats();
    this.props.fetchRequests(this.state.selectedDay);
  }
  tabPane() {
    const {
      activeTab,
      stats: {
        banned_users_today,
        banned_users_previous_day,
        banned_users_last_7_days,
        banned_users_this_month,
        banned_users_last_month,
      },
      usersTable: {
        users_registred_today,
        users_registred_yesterday,
        users_registred_last7days,
        users_registred_thismonth,
        users_registred_lastmonth,
      },
      rewardsTable: { resolution_reward },
      isLoading,
    } = this.props;

    //RESOLUTION REWARDS
    let resRewPayment = 0;
    let resRewCount = 0;
    if (resolution_reward.length > 0) {
      let countTogether = [];
      resolution_reward.forEach((res) => {
        if (countTogether.some((ct) => ct.resolution === res.resolution)) {
          const index = countTogether.findIndex(
            (ct) => ct.resolution === res.resolution
          );
          countTogether[index].count += 1;
          resRewCount += 1;
          resRewPayment += res.payment;
          countTogether[index].payment += res.payment;
        } else {
          resRewPayment += res.payment;
          resRewCount += 1;
          countTogether.push({
            resolution: res.resolution,
            payment: res.payment,
            count: 1,
          });
        }
      });
      this.setState({ stateResolutionReward: countTogether });
    } else {
      if (
        !this.state.stateResolutionReward ||
        this.state.stateResolutionReward.length !== 0
      ) {
        this.setState({ stateResolutionReward: [] });
      }
    }

    //REGISTRATION USERS GET DEVICE MODELS
    if (users_registred_today.length > 0 && users_registred_today !== "none") {
      if (users_registred_today.length > 4) {
        let users_models = [{ model: "other", count: 0 }];
        users_registred_today.map((usr) => {
          const i = users_models.findIndex((e) => {
            if (
              usr.device_model === "SM-A037U" ||
              usr.device_model === "moto g pure" ||
              usr.device_model === "SM-A136U" ||
              usr.device_model === "SM-A326U"
            ) {
              return e.model === usr.device_model;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else if (i === -2) {
            users_models[0].count = users_models[0].count + 1;
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersModelToday) {
          this.setState({ stateUsersModelToday: users_models });
        }
      } else {
        let users_models = [];
        users_registred_today.map((usr) => {
          const i = users_models.findIndex((e) => {
            return e.model === usr.device_model;
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersModelToday) {
          this.setState({ stateUsersModelToday: users_models });
        }
      }
    }
    if (
      users_registred_yesterday.length > 0 &&
      users_registred_yesterday !== "none"
    ) {
      if (users_registred_yesterday.length > 4) {
        let users_models = [{ model: "other", count: 0 }];
        users_registred_yesterday.map((usr) => {
          const i = users_models.findIndex((e) => {
            if (
              usr.device_model === "SM-A037U" ||
              usr.device_model === "moto g pure" ||
              usr.device_model === "SM-A136U" ||
              usr.device_model === "SM-A326U"
            ) {
              return e.model === usr.device_model;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else if (i === -2) {
            users_models[0].count = users_models[0].count + 1;
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersModelYesterday) {
          this.setState({ stateUsersModelYesterday: users_models });
        }
      } else {
        let users_models = [];
        users_registred_yesterday.map((usr) => {
          const i = users_models.findIndex((e) => {
            return e.model === usr.device_model;
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersModelYesterday) {
          this.setState({ stateUsersModelYesterday: users_models });
        }
      }
    }
    if (
      users_registred_last7days.length > 0 &&
      users_registred_last7days !== "none"
    ) {
      if (users_registred_last7days.length > 4) {
        let users_models = [{ model: "other", count: 0 }];
        users_registred_last7days.map((usr) => {
          const i = users_models.findIndex((e) => {
            if (
              usr.device_model === "SM-A037U" ||
              usr.device_model === "moto g pure" ||
              usr.device_model === "SM-A136U" ||
              usr.device_model === "SM-A326U"
            ) {
              return e.model === usr.device_model;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else if (i === -2) {
            users_models[0].count = users_models[0].count + 1;
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersModelLast7days) {
          this.setState({ stateUsersModelLast7days: users_models });
        }
      } else {
        let users_models = [];
        users_registred_last7days.map((usr) => {
          const i = users_models.findIndex((e) => {
            return e.model === usr.device_model;
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersModelLast7days) {
          this.setState({ stateUsersModelLast7days: users_models });
        }
      }
    }
    if (
      users_registred_thismonth.length > 0 &&
      users_registred_thismonth !== "none"
    ) {
      if (users_registred_thismonth.length > 4) {
        let users_models = [{ model: "other", count: 0 }];
        users_registred_thismonth.map((usr) => {
          const i = users_models.findIndex((e) => {
            if (
              usr.device_model === "SM-A037U" ||
              usr.device_model === "moto g pure" ||
              usr.device_model === "SM-A136U" ||
              usr.device_model === "SM-A326U"
            ) {
              return e.model === usr.device_model;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else if (i === -2) {
            users_models[0].count = users_models[0].count + 1;
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersModelThisMonth) {
          this.setState({ stateUsersModelThisMonth: users_models });
        }
      } else {
        let users_models = [];
        users_registred_thismonth.map((usr) => {
          const i = users_models.findIndex((e) => {
            return e.model === usr.device_model;
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersModelThisMonth) {
          this.setState({ stateUsersModelThisMonth: users_models });
        }
      }
    }
    if (
      users_registred_lastmonth.length > 0 &&
      users_registred_lastmonth !== "none"
    ) {
      if (users_registred_lastmonth.length > 4) {
        let users_models = [{ model: "other", count: 0 }];
        users_registred_lastmonth.map((usr) => {
          const i = users_models.findIndex((e) => {
            if (
              usr.device_model === "SM-A037U" ||
              usr.device_model === "moto g pure" ||
              usr.device_model === "SM-A136U" ||
              usr.device_model === "SM-A326U"
            ) {
              return e.model === usr.device_model;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else if (i === -2) {
            users_models[0].count = users_models[0].count + 1;
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });

        if (!this.state.stateUsersModelLastMonth) {
          this.setState({ stateUsersModelLastMonth: users_models });
        }
      } else {
        let users_models = [];
        users_registred_lastmonth.map((usr) => {
          const i = users_models.findIndex((e) => {
            return e.model === usr.device_model;
          });
          if (i === -1) {
            users_models.push({ model: usr.device_model, count: 1 });
          } else {
            users_models[i].count = users_models[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersModelLastMonth) {
          this.setState({ stateUsersModelLastMonth: users_models });
        }
      }
    }

    //REGISTRATION USERS GET RESOLUTIONS
    if (users_registred_today.length > 0 && users_registred_today !== "none") {
      if (users_registred_today.length > 4) {
        let users_res = [{ res: "other", count: 0 }];
        users_registred_today.map((usr) => {
          const i = users_res.findIndex((e) => {
            if (
              usr.resolution === "1080x2400" ||
              usr.resolution === "720x1600" ||
              usr.resolution === "1080x2340" ||
              usr.resolution === "720x1440"
            ) {
              return e.res === usr.resolution;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else if (i === -2) {
            users_res[0].count = users_res[0].count + 1;
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResToday) {
          this.setState({ stateUsersResToday: users_res });
        }
      } else {
        let users_res = [];
        users_registred_today.map((usr) => {
          const i = users_res.findIndex((e) => {
            return e.res === usr.resolution;
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResToday) {
          this.setState({ stateUsersResToday: users_res });
        }
      }
    }
    if (
      users_registred_yesterday.length > 0 &&
      users_registred_yesterday !== "none"
    ) {
      if (users_registred_yesterday.length > 4) {
        let users_res = [{ res: "other", count: 0 }];
        users_registred_yesterday.map((usr) => {
          const i = users_res.findIndex((e) => {
            if (
              usr.resolution === "1080x2400" ||
              usr.resolution === "720x1600" ||
              usr.resolution === "1080x2340" ||
              usr.resolution === "720x1440"
            ) {
              return e.res === usr.resolution;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else if (i === -2) {
            users_res[0].count = users_res[0].count + 1;
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResYesterday) {
          this.setState({ stateUsersResYesterday: users_res });
        }
      } else {
        let users_res = [];
        users_registred_yesterday.map((usr) => {
          const i = users_res.findIndex((e) => {
            return e.res === usr.resolution;
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResYesterday) {
          this.setState({ stateUsersResYesterday: users_res });
        }
      }
    }
    if (
      users_registred_last7days.length > 0 &&
      users_registred_last7days !== "none"
    ) {
      if (users_registred_last7days.length > 4) {
        let users_res = [{ res: "other", count: 0 }];
        users_registred_last7days.map((usr) => {
          const i = users_res.findIndex((e) => {
            if (
              usr.resolution === "1080x2400" ||
              usr.resolution === "720x1600" ||
              usr.resolution === "1080x2340" ||
              usr.resolution === "720x1440"
            ) {
              return e.res === usr.resolution;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else if (i === -2) {
            users_res[0].count = users_res[0].count + 1;
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResLast7days) {
          this.setState({ stateUsersResLast7days: users_res });
        }
      } else {
        let users_res = [];
        users_registred_last7days.map((usr) => {
          const i = users_res.findIndex((e) => {
            return e.res === usr.resolution;
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResLast7days) {
          this.setState({ stateUsersResLast7days: users_res });
        }
      }
    }
    if (
      users_registred_thismonth.length > 0 &&
      users_registred_thismonth !== "none"
    ) {
      if (users_registred_thismonth.length > 4) {
        let users_res = [{ res: "other", count: 0 }];
        users_registred_thismonth.map((usr) => {
          const i = users_res.findIndex((e) => {
            if (
              usr.resolution === "1080x2400" ||
              usr.resolution === "720x1600" ||
              usr.resolution === "1080x2340" ||
              usr.resolution === "720x1440"
            ) {
              return e.res === usr.resolution;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else if (i === -2) {
            users_res[0].count = users_res[0].count + 1;
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResThisMonth) {
          this.setState({ stateUsersResThisMonth: users_res });
        }
      } else {
        let users_res = [];
        users_registred_thismonth.map((usr) => {
          const i = users_res.findIndex((e) => {
            return e.res === usr.resolution;
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResThisMonth) {
          this.setState({ stateUsersResThisMonth: users_res });
        }
      }
    }
    if (
      users_registred_lastmonth.length > 0 &&
      users_registred_lastmonth !== "none"
    ) {
      if (users_registred_lastmonth.length > 4) {
        let users_res = [{ res: "other", count: 0 }];
        users_registred_lastmonth.map((usr) => {
          const i = users_res.findIndex((e) => {
            if (
              usr.resolution === "1080x2400" ||
              usr.resolution === "720x1600" ||
              usr.resolution === "1080x2340" ||
              usr.resolution === "720x1440"
            ) {
              return e.res === usr.resolution;
            } else {
              return -2;
            }
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else if (i === -2) {
            users_res[0].count = users_res[0].count + 1;
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResLastMonth) {
          this.setState({ stateUsersResLastMonth: users_res });
        }
      } else {
        let users_res = [];
        users_registred_lastmonth.map((usr) => {
          const i = users_res.findIndex((e) => {
            return e.res === usr.resolution;
          });
          if (i === -1) {
            users_res.push({ res: usr.resolution, count: 1 });
          } else {
            users_res[i].count = users_res[i].count + 1;
          }
          return null;
        });
        if (!this.state.stateUsersResLastMonth) {
          this.setState({ stateUsersResLastMonth: users_res });
        }
      }
    }

    //DEVICE MODEL FULL COUNT
    let regDeviceToday = 0;
    if (this.state.stateUsersModelToday.length === 1) {
      regDeviceToday = 1;
    } else if (this.state.stateUsersModelToday.length > 0) {
      regDeviceToday = this.state.stateUsersModelToday.reduce(
        (prev, current) => {
          return (prev?.count ? prev.count : prev) + current.count;
        }
      );
    } else {
      regDeviceToday = 0;
    }

    let regDeviceYesterday = 0;
    if (this.state.stateUsersModelYesterday.length === 1) {
      regDeviceYesterday = 1;
    } else if (this.state.stateUsersModelYesterday.length > 0) {
      regDeviceYesterday = this.state.stateUsersModelYesterday.reduce(
        (prev, current) => {
          return (prev?.count ? prev.count : prev) + current.count;
        }
      );
    } else {
      regDeviceYesterday = 0;
    }

    let regDeviceLast7days = 0;
    if (this.state.stateUsersModelLast7days.length === 1) {
      regDeviceLast7days = 1;
    } else if (this.state.stateUsersModelLast7days.length > 0) {
      regDeviceLast7days = this.state.stateUsersModelLast7days.reduce(
        (prev, current) => {
          return (prev?.count ? prev.count : prev) + current.count;
        }
      );
    } else {
      regDeviceLast7days = 0;
    }

    let regDeviceThisMonth = 0;
    if (this.state.stateUsersModelThisMonth.length === 1) {
      regDeviceThisMonth = 1;
    } else if (this.state.stateUsersModelThisMonth.length > 0) {
      regDeviceThisMonth = this.state.stateUsersModelThisMonth.reduce(
        (prev, current) => {
          return (prev?.count ? prev.count : prev) + current.count;
        }
      );
    } else {
      regDeviceThisMonth = 0;
    }

    let regDeviceLastMonth = 0;
    if (this.state.stateUsersModelLastMonth.length === 1) {
      regDeviceLastMonth = 1;
    } else if (this.state.stateUsersModelLastMonth.length > 0) {
      regDeviceLastMonth = this.state.stateUsersModelLastMonth.reduce(
        (prev, current) => {
          return (prev?.count ? prev.count : prev) + current.count;
        }
      );
    } else {
      regDeviceLastMonth = 0;
    }

    //RESOLUTION USER FULL COUNT
    let regResToday = 0;
    if (this.state.stateUsersResToday.length === 1) {
      regResToday = 1;
    } else if (this.state.stateUsersResToday.length > 0) {
      regResToday = this.state.stateUsersResToday.reduce((prev, current) => {
        return (prev?.count ? prev.count : prev) + current.count;
      });
    } else {
      regResToday = 0;
    }

    let regResYesterday = 0;
    if (this.state.stateUsersResYesterday.length === 1) {
      regResYesterday = 1;
    } else if (this.state.stateUsersResYesterday.length > 0) {
      regResYesterday = this.state.stateUsersResYesterday.reduce(
        (prev, current) => {
          return (prev?.count ? prev.count : prev) + current.count;
        }
      );
    } else {
      regResYesterday = 0;
    }

    let regResLast7days = 0;
    if (this.state.stateUsersResLast7days.length === 1) {
      regResLast7days = 1;
    } else if (this.state.stateUsersResLast7days.length > 0) {
      regResLast7days = this.state.stateUsersResLast7days.reduce(
        (prev, current) => {
          return (prev?.count ? prev.count : prev) + current.count;
        }
      );
    } else {
      regResLast7days = 0;
    }

    let regResMonth = 0;
    if (this.state.stateUsersResThisMonth.length === 1) {
      regResMonth = 1;
    } else if (this.state.stateUsersResThisMonth.length > 0) {
      regResMonth = this.state.stateUsersResThisMonth.reduce(
        (prev, current) => {
          return (prev?.count ? prev.count : prev) + current.count;
        }
      );
    } else {
      regResMonth = 0;
    }

    let regResLastMonth = 0;
    if (this.state.stateUsersResLastMonth.length === 1) {
      regResLastMonth = 1;
    } else if (this.state.stateUsersResLastMonth.length > 0) {
      regResLastMonth = this.state.stateUsersResLastMonth.reduce(
        (prev, current) => {
          return (prev?.count ? prev.count : prev) + current.count;
        }
      );
    } else {
      regResLastMonth = 0;
    }

    //DEVICE REGISTRATION TABLES
    const tableModelsToday = (
      <>
        {this.state.stateUsersModelToday.map((obj) => {
          if (obj.model !== "other") {
            return (
              <tr>
                <td>{obj.model}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regDeviceToday) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersModelToday.length > 0 &&
          this.state.stateUsersModelToday[0].model === "other" && (
            <tr style={{ backgroundColor: "#eae9e9" }}>
              <td>{this.state.stateUsersModelToday[0].model}</td>
              <td>{this.state.stateUsersModelToday[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersModelToday[0].count / regDeviceToday) *
                    100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );
    const tableModelsYesterday = (
      <>
        {this.state.stateUsersModelYesterday.map((obj) => {
          if (obj.model !== "other") {
            return (
              <tr id={obj.model}>
                <td>{obj.model}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regDeviceYesterday) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersModelYesterday.length > 0 &&
          this.state.stateUsersModelYesterday[0].model === "other" && (
            <tr style={{ backgroundColor: "#eae9e9" }}>
              <td>{this.state.stateUsersModelYesterday[0].model}</td>
              <td>{this.state.stateUsersModelYesterday[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersModelYesterday[0].count /
                    regDeviceYesterday) *
                    100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );
    const tableModelsLast7days = (
      <>
        {this.state.stateUsersModelLast7days.map((obj) => {
          if (obj.model !== "other") {
            return (
              <tr>
                <td>{obj.model}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regDeviceLast7days) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersModelLast7days.length > 0 &&
          this.state.stateUsersModelLast7days[0].model === "other" && (
            <tr style={{ backgroundColor: "#eae9e9" }}>
              <td>{this.state.stateUsersModelLast7days[0].model}</td>
              <td>{this.state.stateUsersModelLast7days[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersModelLast7days[0].count /
                    regDeviceLast7days) *
                    100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );
    const tableModelsThisMonth = (
      <>
        {this.state.stateUsersModelThisMonth.map((obj) => {
          if (obj.model !== "other") {
            return (
              <tr>
                <td>{obj.model}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regDeviceThisMonth) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersModelThisMonth.length > 0 &&
          this.state.stateUsersModelThisMonth[0].model === "other" && (
            <tr style={{ backgroundColor: "#eae9e9" }}>
              <td>{this.state.stateUsersModelThisMonth[0].model}</td>
              <td>{this.state.stateUsersModelThisMonth[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersModelThisMonth[0].count /
                    regDeviceThisMonth) *
                    100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );
    const tableModelsLastMonth = (
      <>
        {this.state.stateUsersModelLastMonth.map((obj) => {
          if (obj.model !== "other") {
            return (
              <tr>
                <td>{obj.model}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regDeviceLastMonth) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersModelLastMonth.length > 0 &&
          this.state.stateUsersModelLastMonth[0].model === "other" && (
            <tr style={{ backgroundColor: "#eae9e9" }}>
              <td>{this.state.stateUsersModelLastMonth[0].model}</td>
              <td>{this.state.stateUsersModelLastMonth[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersModelLastMonth[0].count /
                    regDeviceLastMonth) *
                    100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );

    //RESOLUTION REGISTRATION TABLES
    const tableResolutionToday = (
      <>
        {this.state.stateUsersResToday.map((obj) => {
          if (obj.res !== "other") {
            return (
              <tr>
                <td>{obj.res}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regResToday) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersResToday.length > 0 &&
          this.state.stateUsersResToday[0].res === "other" && (
            <tr>
              <td>{this.state.stateUsersResToday[0].res}</td>
              <td>{this.state.stateUsersResToday[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersResToday[0].count / regResToday) * 100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );
    const tableResolutionYesterday = (
      <>
        {this.state.stateUsersResYesterday.map((obj) => {
          if (obj.res !== "other") {
            return (
              <tr>
                <td>{obj.res}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regResYesterday) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersResYesterday.length > 0 &&
          this.state.stateUsersResYesterday[0].res === "other" && (
            <tr>
              <td>{this.state.stateUsersResYesterday[0].res}</td>
              <td>{this.state.stateUsersResYesterday[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersResYesterday[0].count /
                    regResYesterday) *
                    100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );
    const tableResolutionLast7days = (
      <>
        {this.state.stateUsersResLast7days.map((obj) => {
          if (obj.res !== "other") {
            return (
              <tr>
                <td>{obj.res}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regResLast7days) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersResLast7days.length > 0 &&
          this.state.stateUsersResLast7days[0].res === "other" && (
            <tr>
              <td>{this.state.stateUsersResLast7days[0].res}</td>
              <td>{this.state.stateUsersResLast7days[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersResLast7days[0].count /
                    regResLast7days) *
                    100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );
    const tableResolutionThisMonth = (
      <>
        {this.state.stateUsersResThisMonth.map((obj) => {
          if (obj.res !== "other") {
            return (
              <tr>
                <td>{obj.res}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regResMonth) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersResThisMonth.length > 0 &&
          this.state.stateUsersResThisMonth[0].res === "other" && (
            <tr>
              <td>{this.state.stateUsersResThisMonth[0].res}</td>
              <td>{this.state.stateUsersResThisMonth[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersResThisMonth[0].count / regResMonth) *
                    100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );
    const tableResolutionLastMonth = (
      <>
        {this.state.stateUsersResLastMonth.map((obj) => {
          if (obj.res !== "other") {
            return (
              <tr>
                <td>{obj.res}</td>
                <td>{obj.count}</td>
                <td>{Math.round((obj.count / regResLastMonth) * 100)}%</td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.stateUsersResLastMonth.length > 0 &&
          this.state.stateUsersResLastMonth[0].res === "other" && (
            <tr style={{ backgroundColor: "#eae9e9" }}>
              <td>{this.state.stateUsersResLastMonth[0].res}</td>
              <td>{this.state.stateUsersResLastMonth[0].count}</td>
              <td>
                {Math.round(
                  (this.state.stateUsersResLastMonth[0].count /
                    regResLastMonth) *
                    100
                )}
                %
              </td>
            </tr>
          )}
      </>
    );

    const FilterDayButtons = () => {
      return (
        <div>
          {/* <Button
            color={
              this.state.selectedDay === "testday" ? `success` : `secondary`
            }
            className="mx-1"
            onClick={() => {
              this.setState({
                stateResolutionReward: [],
                selectedDay: "testday",
              });
              this.props.fetchRequests("testday");
            }}
          >
            Test day
          </Button> */}
          <Button
            color={this.state.selectedDay === "today" ? `success` : `secondary`}
            className="mx-1"
            onClick={() => {
              this.setState({
                stateResolutionReward: [],
                selectedDay: "today",
              });
              this.props.fetchRequests("today");
            }}
          >
            Today
          </Button>
          <Button
            color={
              this.state.selectedDay === "yesterday" ? `success` : `secondary`
            }
            className="mx-1"
            onClick={() => {
              this.setState({ selectedDay: "yesterday" });
              this.props.fetchRequests("yesterday");
            }}
          >
            Yesterday
          </Button>
          <Button
            color={
              this.state.selectedDay === "oneweek" ? `success` : `secondary`
            }
            className="mx-1"
            onClick={() => {
              this.setState({ selectedDay: "oneweek" });
              this.props.fetchRequests("oneweek");
            }}
          >
            One week
          </Button>
          <Button
            color={
              this.state.selectedDay === "thismonth" ? `success` : `secondary`
            }
            className="mx-1"
            onClick={() => {
              this.setState({ selectedDay: "thismonth" });
              this.props.fetchRequests("thismonth");
            }}
          >
            This month
          </Button>
          <Button
            color={
              this.state.selectedDay === "lastmonth" ? `success` : `secondary`
            }
            className="mx-1"
            onClick={() => {
              this.setState({ selectedDay: "lastmonth" });
              this.props.fetchRequests("lastmonth");
            }}
          >
            Last month
          </Button>
        </div>
      );
    };

    return (
      <>
        <TabPane tabId={1}>
          {activeTab === 1 && (
            <div>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <i className="fa fa-dollar"></i> Banned users
                    </CardHeader>
                    <CardBody>
                      <Row className="text-center">
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <div className="text-muted">Today</div>
                          <strong>{banned_users_today}</strong>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <div className="text-muted">Yesterday</div>
                          <strong>{banned_users_previous_day}</strong>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <div className="text-muted">Last 7 days</div>
                          <strong>{banned_users_last_7_days}</strong>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <div className="text-muted">This month</div>
                          <strong>{banned_users_this_month}</strong>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <div className="text-muted">Last month</div>
                          <strong>{banned_users_last_month}</strong>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <i className="fa icon-chart"></i>Registred Resolutions
                    </CardHeader>
                    <CardBody>
                      <Row className="text-center">
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>Today</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Resolutions</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableResolutionToday}</tbody>
                          </Table>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>Yesterday</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Resolutions</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableResolutionYesterday}</tbody>
                          </Table>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>Last 7 days</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Resolutions</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableResolutionLast7days}</tbody>
                          </Table>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>This month</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Resolutions</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableResolutionThisMonth}</tbody>
                          </Table>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>Last month</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Resolutions</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableResolutionLastMonth}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <i className="fa icon-chart"></i>Registred Device Models
                    </CardHeader>
                    <CardBody>
                      <Row className="text-center">
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>Today</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Device model</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableModelsToday}</tbody>
                          </Table>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>Yesterday</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Device model</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableModelsYesterday}</tbody>
                          </Table>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>Last 7 days</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Device model</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableModelsLast7days}</tbody>
                          </Table>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>This month</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Device model</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableModelsThisMonth}</tbody>
                          </Table>
                        </Col>
                        <Col sm={12} md className="mb-sm-2 mb-0">
                          <strong>Last month</strong>
                          <Table className="my-2" responsive size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">Device model</th>
                                <th className="text-muted">Count</th>
                                <th className="text-muted">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>{tableModelsLastMonth}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </TabPane>
        <TabPane tabId={2}>
          <center>
            <Card>
              <CardBody>
                <FilterDayButtons />
                {isLoading ? (
                  <>
                    Loading...{" "}
                    <Spinner
                      style={{ marginTop: "4rem" }}
                      color="success"
                      size="sm"
                    />
                  </>
                ) : (
                  <>
                    <label
                      className="text-muted mt-2"
                      style={{ fontWeight: "bold" }}
                    >
                      Payments total: ${resRewPayment} / Count total:{" "}
                      {resRewCount}x
                    </label>
                    <Table className="my-2" style={{ width: 500 }} size="sm">
                      <thead>
                        <tr>
                          <th className="text-muted">Resolution</th>
                          <th className="text-muted">Payment</th>
                          <th className="text-muted">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.stateResolutionReward?.map((obj, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ fontWeight: "bold" }}>
                                {obj.resolution}
                              </td>
                              <td>{`($${obj.payment}) + ${Math.round(
                                (obj.payment / resRewPayment) * 100
                              )}%`}</td>
                              <td>{`(${obj.count}x) + ${Math.round(
                                (obj.count / resRewCount) * 100
                              )}%`}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                )}
              </CardBody>
            </Card>
          </center>
        </TabPane>
      </>
    );
  }
  render() {
    const { activeTab } = this.props;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={activeTab === 1}
                  onClick={() => {
                    this.props.setFraudalyticsTab(1);
                  }}
                >
                  <i className="icon-check"></i> Fraud Analytics
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 2}
                  onClick={() => {
                    this.props.setFraudalyticsTab(2);
                  }}
                >
                  <i className="icon-check"></i> Resolutions Rewards
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent style={{ height: "100%" }} activeTab={activeTab}>
              {this.tabPane()}
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeTab: state.fraudalytics.fraudalytics.tabid,
    stats: state.fraudalytics.fraudalytics.stats,
    usersTable: state.fraudalytics.fraudalytics.usersTable,
    rewardsTable: state.fraudalytics.fraudalytics.rewardsTable,
    isLoading: state.fraudalytics.fraudalytics.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default withReducer(
  "fraudalytics",
  reducer
)(connect(mapStateToProps, mapDispatchToProps)(Fraudalytics));
