import { apiSagaRequest, showMessage } from "app/store/actions/tapchamps";

export const GET_REVENUES = "[REVENUES] GET REVENUES";
export const CLEAR_REVENUES = "[REVENUES CLEAR REVENUES";

export function getRevenues() {
  return (dispatch, getState) => {
    const {
      revenues: {
        filter: { current },
      },
    } = getState();
    if (current.startDate === null || current.endDate === null) {
      return;
    }
    dispatch({
      type: CLEAR_REVENUES,
    });
    return dispatch(
      apiSagaRequest("API_REVENUES_REQUEST", {
        data: {
          ...current,
          startDate: current.startDate.utc().startOf("day").unix(),
          endDate: current.endDate.utc().endOf("day").unix(),
        },
        method: "post",
        url: "/api/revenues",
      })
    ).then((response) => {
      if (response.data.error) {
        dispatch(showMessage({ message: response.data.error, type: "error" }));
        return;
      }
      dispatch({
        type: GET_REVENUES,
        payload: response.data,
      });
    });
  };
}
