// React DateRangePicker
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";
import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import { DateRangePicker } from "react-dates";

class RevenuesView extends Component {
  constructor(props) {
    super(props);
    this.onGroupByChange = this.onGroupByChange.bind(this);
    this.onDateGroupChange = this.onDateGroupChange.bind(this);
    this.onLoadClick = this.onLoadClick.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.onGameChange = this.onGameChange.bind(this);
    this.state = {};

    this.options = {
      sortIndicator: true,
      hideSizePerPage: true,
      paginationSize: 20,
      hidePageListOnlyOnePage: true,
      alwaysShowAllBtns: false,
      withFirstAndLast: false,
    };
  }

  componentDidMount() {
    this.props.getRevenues();
  }

  onLoadClick(e) {
    this.props.getRevenues();
  }

  onUsernameChange(e) {
    this.props.setUsernameFilter(e.target.value);
  }
  onGameChange(e) {
    this.props.setGameFilter(e.target.value);
  }
  onGroupByChange(e) {
    this.props.setGroupBy(e.target.value);
  }
  onDateGroupChange(e) {
    this.props.setDateGroup(e.target.value);
  }
  render() {
    const {
      filter: {
        current: { startDate, endDate, groupBy, username, game },
        groupByOptions,
      },
    } = this.props;
    const { total, data, fields } = this.props.revenues;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col md={3}></Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label htmlFor="groupby">Game Package Name</Label>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="game"
                        id="game"
                        bsSize="sm"
                        onChange={this.onGameChange}
                        value={game}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label htmlFor="groupby">Username</Label>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="username"
                        id="username"
                        bsSize="sm"
                        onChange={this.onUsernameChange}
                        value={username}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label htmlFor="groupby">Group By</Label>
                      <Input
                        type="select"
                        name="groupby"
                        id="groupby"
                        bsSize="sm"
                        onChange={this.onGroupByChange}
                        value={groupBy}
                      >
                        {Object.keys(groupByOptions).map((k) => (
                          <option key={k} value={k}>
                            {groupByOptions[k].value}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Date Range</Label>
                      <DateRangePicker
                        isOutsideRange={() => false}
                        startDate={startDate}
                        startDateId="startDate"
                        endDate={endDate}
                        endDateId="endDate"
                        small
                        onDatesChange={({ startDate, endDate }) =>
                          this.props.setDateRange(startDate, endDate)
                        }
                        focusedInput={this.state.focusedInput}
                        onFocusChange={(focusedInput) =>
                          this.setState({ focusedInput })
                        }
                        orientation={this.state.orientation}
                        openDirection={this.state.openDirection}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button size="sm" color="primary" onClick={this.onLoadClick}>
                  <i className="fa fa-search"></i> Load
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {fields.length > 0 && (
              <Card>
                <CardHeader>
                  <b>Total: {total}</b>
                </CardHeader>
                <CardBody>
                  <BootstrapTable
                    data={data}
                    striped
                    condensed
                    hover
                    pagination
                    options={this.options}
                  >
                    {fields.map((f) => (
                      <TableHeaderColumn
                        key={f.id}
                        dataField={f.id}
                        isKey={f.isKey}
                        dataSort={false}
                      >
                        {f.name}
                      </TableHeaderColumn>
                    ))}
                  </BootstrapTable>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default RevenuesView;
