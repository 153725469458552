import Profit from "./Profit";
import ProfitAverageUnits from "./ProfitAverageUnits";
import { authRoles } from "app/auth";

export const ProfitConfig = {
  auth: [...authRoles.profitViewer],
  routes: [
    {
      path: "/profit/average-units",
      component: ProfitAverageUnits,
      name: "Average Units Per User Per Day",
    },
    {
      path: "/profit",
      component: Profit,
      name: "Profit",
    },
  ],
};
