import axios from "axios";
export const GET_COUNTRIES = "[COUNTRIES] GET COUNTRIES";

export function fetchCountries() {
  return (dispatch) => {
    const request = axios.get(`/api/countries/allactive`);
    return request.then((response) => {
      dispatch({
        type: GET_COUNTRIES,
        payload: response.data,
      });
    });
  };
}
