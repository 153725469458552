import Referals from "./Referals";
import { authRoles } from "app/auth";

export const ReferalsConfig = {
  auth: [...authRoles.admin, ...authRoles.referalsViewer],
  routes: [
    {
      path: "/referals",
      component: Referals,
      name: "Referals",
    },
  ],
};
