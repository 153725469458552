import * as Actions from "../actions";

const initialState = {};

const filesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_FILE: {
      const { data } = action.payload;
      return {
        ...data,
      };
    }
    default: {
      return state;
    }
  }
};

export default filesReducer;
