import React from "react";
import { authRoles } from "app/auth";

export const PermissionsConfig = {
  auth: authRoles.admin,
  routes: [
    {
      path: "/permissions/:id",
      component: React.lazy(() => import("./user/User")),
      name: "Edit",
    },
    {
      path: "/permissions",
      component: React.lazy(() => import("./users/Users")),
      name: "Permissions",
    },
  ],
};
