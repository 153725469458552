import axios from "axios";
import { showMessage } from "app/store/actions/tapchamps";

export const GET_FILE = "[GENERAL PAGES] GET FILE";
export const SAVE_FILE = "[GENERAL PAGES] SAVE FILE";

export function getFile(id) {
  const request = axios.get("/api/general/files/" + id);

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_FILE,
        payload: response.data,
      })
    );
}

export function saveFile(id, data, country) {
  return (dispatch) => {
    const request = axios.post("/api/general/files/" + id, { data, country });
    return request.then((response) =>
      Promise.all([
        dispatch({
          type: SAVE_FILE,
        }),
        dispatch(showMessage({ message: "File Has Been Saved" })),
      ]).then(() => dispatch(getFile(id)))
    );
  };
}
