import React, { Component } from "react";

import AppContext from "app/AppContext";
import { TapchampsUtils } from "@tapchamps";
import { connect } from "react-redux";
import { matchRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";

class TapchampsAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      needsTwoStep: false,
      routes,
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, userRole, twoStepToken, twoStepTokenExpiryTime } = props;
    const { pathname } = location;

    const matched = matchRoutes(state.routes, pathname)[0];
    const now = new Date();

    const accessGranted = matched
      ? TapchampsUtils.hasPermission(matched.route.auth, userRole)
      : true;

    return {
      accessGranted,
      // Also check if the user has completed two-step authentication
      needsTwoStep:
        accessGranted &&
        (!twoStepToken || now.getTime() > twoStepTokenExpiryTime),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.accessGranted !== this.state.accessGranted ||
      nextState.needsTwoStep !== this.state.needsTwoStep
    );
  }

  redirectRoute() {
    const { location, userRole, twoStepToken, history } = this.props;
    const { pathname, state } = location;
    const redirectUrl = state && state.redirectUrl ? state.redirectUrl : "/";

    /*
        User is guest
        Redirect to Login Page
        */
    if (!userRole || userRole.length === 0) {
      history.push({
        pathname: "/login",
        state: { redirectUrl: pathname },
      });
    } else if (!twoStepToken) {
      // User is authenticated but needs to complete two-step verification
      history.push({
        pathname: "/twostepsauth",
        state: { redirectUrl: pathname },
      });
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
      history.push({
        pathname: redirectUrl,
      });
    }
  }

  render() {
    const { accessGranted, needsTwoStep } = this.state;
    const { location, history } = this.props;
    const { pathname } = location;

    if (
      this.props.userRole &&
      this.props.userRole.length !== 0 &&
      needsTwoStep
    ) {
      history.push({
        pathname: "/twostepsauth",
        state: { redirectUrl: pathname },
      });
    }
    return accessGranted ? (
      <React.Fragment>{this.props.children}</React.Fragment>
    ) : null;
  }
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role,
    twoStepToken: auth.user.data.token,
    twoStepTokenExpiryTime: localStorage.getItem("secretExpiryTime"),
  };
}

TapchampsAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(TapchampsAuthorization));
