import Fraudalytics from "./Fraudalytics";
import { authRoles } from "app/auth";

export const FraudalyticsConfig = {
  auth: [...authRoles.admin, ...authRoles.fraudalyticsViewer],
  routes: [
    {
      path: "/fraudalytics",
      component: Fraudalytics,
      name: "Fraudalytics",
    },
  ],
};
