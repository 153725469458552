import { apiSagaRequest, showMessage } from "app/store/actions/tapchamps";

export const FETCH = "[TABLE] FETCH";
export const CLEAN_ALL = "[TABLE] CLEAN ALL";
export const SET_FILTER = "[TABLE] SET FILTER";
export const SET_SORT = "[TABLE] SET SORT";
export const SET_PAGING = "[TABLE] SET PAGING";
export const UPDATE_CELL = "[TABLE] UPDATE CELL";
export const DELETE_ROWS = "[TABLE] DELETE ROWS";
export const CREATE_ROW = "[TABLE] CREATE ROW";
export const SET_EXPANDED_ROW = "[TABLE] SET EXPANDED ROW";
export const SET_LOADING = "[TABLE] SET LOADING";

export function setPaging(type, page, sizePerPage) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { page, sizePerPage, subtype: SET_PAGING },
    });
    dispatch(fetch(type));
  };
}

export function setFilter(type, filter) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { ...filter, subtype: SET_FILTER },
    });
    dispatch(fetch(type));
  };
}

export function setSort(type, sortName, sortOrder) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { sortName, sortOrder, subtype: SET_SORT },
    });
    dispatch(fetch(type));
  };
}

export function clean(type) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { subtype: CLEAN_ALL },
    });
  };
}

export function setLoading(type, value) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { value, subtype: SET_LOADING },
    });
  };
}

export function fetch(type) {
  return (dispatch, getState) => {
    const {
      isp: {
        table: {
          [type]: { table },
        },
      },
    } = getState();

    dispatch(setLoading(type, true));
    return dispatch(
      apiSagaRequest("TABLE_REQUEST", {
        method: "post",
        url: "/api/install/" + type,
        data: { ...table },
      })
    ).then(
      (response) =>
        dispatch({
          type: type,
          payload: { ...response.data, subtype: FETCH },
        }),
      (e) => {
        dispatch(
          showMessage({
            message: e.message ? e.message : e.response.data.errors.message,
            type: "error",
          })
        );
      }
    );
  };
}
