import UserAgents from "./UserAgents";
import Ip from "./Ip";
import Resolution from "./Resolution";
import { authRoles } from "app/auth";

export const DbCallsConfig = {
  auth: [...authRoles.admin, ...authRoles.referalsViewer],
  routes: [
    {
      path: "/dbcalls/useragents",
      component: UserAgents,
      name: "UserAgents",
    },
    {
      path: "/dbcalls/resolution",
      component: Resolution,
      name: "Resolution",
    },
    {
      path: "/dbcalls/ip",
      component: Ip,
      name: "Ip",
    },
  ],
};
