import React from "react";
import { authRoles } from "app/auth";

export const FraudConfig = {
  auth: [...authRoles.admin, ...authRoles.fraudpanelViewer],
  routes: [
    {
      path: "/fraudpanel",
      component: React.lazy(() => import("./Fraud")),
      name: "Fraud Panel",
    },
  ],
};
