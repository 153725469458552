import BreakdownStats from "./BreakdownStats";
import { authRoles } from "app/auth";

export const BreakdownStatsConfig = {
  auth: [...authRoles.admin, ...authRoles.breakdownStatsViewer],
  routes: [
    {
      path: "/breakdownstats",
      component: BreakdownStats,
      name: "Breakdown Stats",
    },
  ],
};
