import AutoClickers from "./AutoClickers";
import { authRoles } from "app/auth";

export const AutoClickersConfig = {
  auth: [...authRoles.admin],
  routes: [
    {
      path: "/autoclickers",
      component: AutoClickers,
      name: "Auto Clickers",
    },
  ],
};
