import { call, put, takeLatest } from "redux-saga/effects";

import axios from "axios";

export default function* mySaga() {
  yield takeLatest("API_PROFIT_REQUEST", profit);
  yield takeLatest("API_AVG_UNITS_REQUEST", averageUnits);
}

function* profit(data) {
  const { payload, meta } = data;
  try {
    const response = yield call(axios, payload);
    yield put({
      type: "API_PROFIT_SUCCESS",
      payload: response,
      meta,
    });
  } catch (e) {
    yield put({
      type: "API_PROFIT_FAILURE",
      payload: e,
      error: true,
      meta,
    });
  }
}

function* averageUnits(data) {
  const { payload, meta } = data;
  try {
    const response = yield call(axios, payload);
    yield put({
      type: "API_AVG_UNITS_SUCCESS",
      payload: response,
      meta,
    });
  } catch (e) {
    yield put({
      type: "API_AVG_UNITS_FAILURE",
      payload: e,
      error: true,
      meta,
    });
  }
}
