import "./react_dates_overrides.css";

import * as Actions from "./store/actions";

import {
  ButtonGroup,
  ButtonToggle,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import { DateRangePicker } from "react-dates";
import { TapchampsUtils } from "@tapchamps";
import ProfitMediaSource from "app/views/Widgets/ProfitMediaSource";
import ProfitCountryMediaSource from "app/views/Widgets/ProfitCountryMediaSource";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

const GROUP_BY = {
  MEDIA: 0,
  DATE: 1,
  COUNTRY: 2,
};

class Profit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediasource: "",
      groupBy: 0,
      focusedInput: null,
      startDate: null,
      endDate: null,
    };

    this.onGroupByChange = this.onGroupByChange.bind(this);
  }
  componentDidMount() {
    const {
      profit: { startDate, endDate },
    } = this.props;
    this.setState({ startDate, endDate }, () => {
      this.props.dispatch(Actions.getProfit());
      this.props.dispatch(Actions.getMediaForSelect());
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  onMediaSelect(selectedList, selectedItem) {
    this.setState({ mediasource: selectedList });

    this.props.dispatch(Actions.getProfit(selectedItem, this.state.groupBy));
  }
  onMediaRemove(selectedList, removedItem) {
    this.setState({
      mediasource: selectedList,
    });

    this.props.dispatch(Actions.getProfit("", this.state.groupBy));
  }
  onGroupByChange(groupBy) {
    this.setState({ groupBy });
    this.props.dispatch(Actions.getProfit(this.state.mediasource, groupBy));
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return loading();
    }

    const canView = TapchampsUtils.hasPermission(
      ["profit-viewer"],
      this.props.user.role
    );

    const {
      profit: { data: profit_data, loading: profit_loading },
    } = this.props;

    if (profit_data.TOTAL) {
      const total = profit_data.TOTAL[0];
      Object.keys(profit_data).forEach((key) => {
        profit_data[key].map((item, index) => {
          if (profit_data[key][index].profit) {
            profit_data[key][index].profitPerc =
              (profit_data[key][index].profit / total.profit) * 100;
          } else {
            profit_data[key][index].profitPerc = 0;
          }
          if (profit_data[key][index].revenue) {
            profit_data[key][index].revenuePerc =
              (profit_data[key][index].revenue / total.revenue) * 100;
          } else {
            profit_data[key][index].revenuePerc = 0;
          }
          if (profit_data[key][index].rewardscost) {
            profit_data[key][index].rewardscostPerc =
              (profit_data[key][index].rewardscost / total.rewardscost) * 100;
          } else {
            profit_data[key][index].rewardscostPerc = 0;
          }
          if (profit_data[key][index].cost) {
            profit_data[key][index].costPerc =
              (profit_data[key][index].cost / total.cost) * 100;
          } else {
            profit_data[key][index].costPerc = 0;
          }
        });
      });
    }

    let tableToRender;
    if (this.state.groupBy === 2) {
      tableToRender = (
        <ProfitCountryMediaSource loading={profit_loading} data={profit_data} />
      );
    } else {
      tableToRender = (
        <ProfitMediaSource
          loading={profit_loading}
          data={profit_data}
          groupBy={this.state.groupBy}
        />
      );
    }

    return (
      canView && (
        <div className="animated fadeIn">
          <Row>
            <Col>
              <ButtonGroup className="float-left mb-2">
                <ButtonToggle
                  active={this.state.groupBy === GROUP_BY.MEDIA}
                  onClick={(e) => {
                    this.onGroupByChange(GROUP_BY.MEDIA);
                  }}
                >
                  Media
                </ButtonToggle>
                <ButtonToggle
                  active={this.state.groupBy === GROUP_BY.DATE}
                  onClick={(e) => {
                    this.onGroupByChange(GROUP_BY.DATE);
                  }}
                >
                  Date
                </ButtonToggle>
                <ButtonToggle
                  active={this.state.groupBy === GROUP_BY.COUNTRY}
                  onClick={(e) => {
                    this.onGroupByChange(GROUP_BY.COUNTRY);
                  }}
                >
                  Country
                </ButtonToggle>
              </ButtonGroup>

              {/* <FormGroup className="float-right mb-2">
                <Multiselect
                  id="search2"
                  loading={loading}
                  style={multiselectStyle}
                  selectionLimit={1}
                  options={_.uniq(mediaforselect.map((obj) => obj.name))}
                  isObject={false}
                  placeholder="Media Source"
                  onSelect={this.onMediaSelect.bind(this)}
                  onRemove={this.onMediaRemove.bind(this)}
                  displayValue="name"
                  selectedValues={this.state.mediasource}
                />
              </FormGroup> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  {this.state.groupBy === GROUP_BY.MEDIA
                    ? "By Media Source"
                    : this.state.groupBy === GROUP_BY.DATE
                    ? "By Date"
                    : "By Country"}
                  <FormGroup className="float-right mb-0">
                    <DateRangePicker
                      isOutsideRange={() => false}
                      startDate={this.state.startDate}
                      startDateId="startDate"
                      endDate={this.state.endDate}
                      endDateId="endDate"
                      small
                      minimumNights={0}
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                        if (focusedInput === null) {
                          setTimeout(() => {
                            this.props.dispatch(
                              Actions.setDateRange(
                                this.state.mediasource,
                                this.state.groupBy,
                                this.state.startDate,
                                this.state.endDate
                              )
                            );
                          }, 500);
                        }
                      }}
                      orientation={this.state.orientation}
                      openDirection={this.state.openDirection}
                    />
                  </FormGroup>
                </CardHeader>
                <CardBody>{tableToRender}</CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profit: state.profit.profit,
    user: state.auth.user,
  };
};

export default withReducer("profit", reducer)(connect(mapStateToProps)(Profit));
