import Marked from "./Marked";
import { authRoles } from "app/auth";

export const MarkedConfig = {
  auth: [...authRoles.admin],
  routes: [
    {
      path: "/marked",
      component: Marked,
      name: "Marked",
    },
  ],
};
