import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";

import {
  BootstrapTable,
  InsertButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { Button, Spinner } from "reactstrap";
import React, { Component } from "react";

import { boolFilter } from "@tapchamps/Types";

class AutoClickersTable extends Component {
  constructor(props) {
    super(props);
    this.handlerClickCleanFiltered = this.handlerClickCleanFiltered.bind(this);
    this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
    this.insertBtn = this.insertBtn.bind(this);
  }

  handlerClickCleanFiltered(e) {
    this.refs.app.cleanFiltered();
    this.refs.blocked.cleanFiltered();
  }

  onAfterSaveCell(row, cellName, cellValue) {
    this.props.onSaveCell(row, cellName, cellValue);
  }

  insertBtn() {
    return <InsertButton onClick={this.props.onInsertBtnClick} />;
  }

  render() {
    const valuesForSelect = Object.keys(boolFilter).map((o) => ({
      value: o,
      text: boolFilter[o],
    }));

    return (
      <>
        <Button
          onClick={this.handlerClickCleanFiltered}
          size="sm"
          outline
          className="mb-1"
        >
          clear filters
        </Button>
        {this.props.loading === true && (
          <Spinner className="ml-2" color="primary" size="sm" />
        )}
        <BootstrapTable
          trClassName="customBootstrapTable"
          data={this.props.loading ? [] : this.props.data}
          remote={true}
          pagination={true}
          cellEdit={{
            mode: "click",
            afterSaveCell: this.onAfterSaveCell,
          }}
          striped
          deleteRow={true}
          selectRow={{ mode: "checkbox" }}
          insertRow={true}
          fetchInfo={{ dataTotalSize: this.props.totalDataSize }}
          options={{
            insertBtn: this.insertBtn,
            defaultSortName: "app",
            defaultSortOrder: "asc",
            onSearchChange: this.props.onSearchChange,
            clearSearch: true,
            onFilterChange: this.props.onFilterChange,
            onDeleteRow: this.props.onDeleteRow,
            onSortChange: this.props.onSortChange,
            sizePerPage: this.props.sizePerPage,
            onPageChange: this.props.onPageChange,
            sizePerPageList: [10, 20, 50, 100],
            page: this.props.currentPage,
            onSizePerPageList: this.props.onSizePerPageList,
          }}
        >
          <TableHeaderColumn dataField="id" isKey hidden>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn
            editable={false}
            ref="app"
            width="200px"
            dataField="app"
            dataSort={false}
            filter={{ type: "TextFilter" }}
          >
            App
          </TableHeaderColumn>
          <TableHeaderColumn
            ref="blocked"
            width="120px"
            dataField="blocked"
            dataSort={false}
            filter={{
              type: "SelectFilter",
              options: boolFilter,
              delay: 2000,
            }}
            editable={{
              type: "select",
              options: { values: valuesForSelect },
            }}
          >
            Blocked
          </TableHeaderColumn>
        </BootstrapTable>
      </>
    );
  }
}

export default AutoClickersTable;
