import * as Actions from "./store/actions";
import * as Yup from "yup";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Formik } from "formik";
import React, { Component } from "react";

import { ISP_CONST } from "@tapchamps/Types";
import IspTable from "app/views/Isp/IspTable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

const tableName = "all";

const validationSchema = function (values) {
  return Yup.object().shape({
    name: Yup.string().required("ISP Name is required!"),
    value: Yup.string(),
  });
};

class Isp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAdd: false,
    };
    this.toggleAdd = this.toggleAdd.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    //this.props.dispatch(Actions.getStats());
    this.props.fetch(tableName);
  }
  onSearchChange(searchText, colInfos, multiColumnSearch) {
    console.log(searchText, colInfos, multiColumnSearch);
  }
  onFilterChange(filterObj) {
    this.props.setFilter(tableName, filterObj);
  }
  onSortChange(sortName, sortOrder) {
    this.props.setSort(tableName, sortName, sortOrder);
  }
  onPageChange(page, sizePerPage) {
    this.props.setPaging(tableName, page, sizePerPage);
  }
  onSizePerPageList(sizePerPage) {}

  onInsertBtnClick() {
    this.setState({
      modalAdd: true,
    });
  }

  onMoreInfoClick(e, row) {
    this.props.history.push("/games/" + row.id);
  }
  onDeleteRow(rowKeys, rows) {
    this.props.deleteRows(tableName, rowKeys);
  }
  onSaveCell(row, cellName, cellValue) {
    this.props.updateCell(tableName, row, cellName, cellValue);
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  toggleAdd() {
    this.setState({
      modalAdd: !this.state.modalAdd,
    });
  }

  async onSubmit(values, actions) {
    await this.props.add(tableName, values, actions);
    this.setState({
      modalAdd: false,
    });
  }

  render() {
    const { loading, isTableLoading } = this.props;
    if (loading) {
      return loading();
    }

    const {
      data,
      table: { totalDataSize, sizePerPage, currentPage },
    } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>ISP</CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">Loading data</div>
                ) : false ? (
                  <div className="text-center">Empty</div>
                ) : (
                  <IspTable
                    onSearchChange={this.onSearchChange.bind(this)}
                    onFilterChange={this.onFilterChange.bind(this)}
                    onSortChange={this.onSortChange.bind(this)}
                    onPageChange={this.onPageChange.bind(this)}
                    onSizePerPageList={this.onSizePerPageList.bind(this)}
                    data={Object.values(data)}
                    loading={isTableLoading}
                    totalDataSize={totalDataSize}
                    sizePerPage={sizePerPage}
                    currentPage={currentPage}
                    onDeleteRow={this.onDeleteRow.bind(this)}
                    onSaveCell={this.onSaveCell.bind(this)}
                    onInsertBtnClick={this.onInsertBtnClick.bind(this)}
                    role={this.props.user.role}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Formik
          initialValues={{ name: "" }}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            status,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            handleReset,
            setFieldTouched,
            setFieldValue,
          }) => (
            <Modal isOpen={this.state.modalAdd} toggle={this.toggleAdd}>
              <Form onSubmit={handleSubmit}>
                <ModalHeader toggle={this.toggleAdd}>
                  Add ISP to table
                </ModalHeader>
                <ModalBody>
                  Enter ISP name (case sensitive):
                  <Input
                    id="name"
                    invalid={touched.name && !!errors.name}
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <FormFeedback>{errors.name}</FormFeedback>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      setFieldValue("value", ISP_CONST.banned);
                    }}
                    type="submit"
                    color="danger"
                    disabled={isSubmitting}
                  >
                    Add as banned
                  </Button>
                  <Button
                    color="success"
                    onClick={() => {
                      setFieldValue("value", ISP_CONST.allowed);
                    }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Add as allowed
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.toggleAdd}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Form>
            </Modal>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    table: state.isp.table[tableName].table,
    data: state.isp.table[tableName].data,
    isTableLoading: state.isp.table[tableName].loading,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default withReducer(
  "isp",
  reducer
)(connect(mapStateToProps, mapDispatchToProps)(Isp));
