import React from "react";
import { authRoles } from "app/auth";
export const GamesConfig = {
  auth: [...authRoles.admin, ...authRoles.gamesViewer],
  routes: [
    {
      path: "/games/new",
      component: React.lazy(() => import("./game/GameNew")),
      name: "New Game",
    },
    {
      path: "/games/:id",
      component: React.lazy(() => import("./game/Game")),
      name: "More Info",
    },
    {
      path: "/games",
      component: React.lazy(() => import("./games/Games")),
      name: "Games",
    },
  ],
};
