import Revenues from "./Revenues";
import { authRoles } from "app/auth";

export const RevenuesConfig = {
  auth: [...authRoles.admin, ...authRoles.revenuesViewer],
  routes: [
    {
      path: "/revenues",
      component: Revenues,
      name: "Revenues",
    },
  ],
};
