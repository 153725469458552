import React from "react";
import { authRoles } from "app/auth";
export const BonusConfig = {
  auth: [...authRoles.admin, ...authRoles.bonusViewer],
  routes: [
    {
      path: "/bonus/seasonalboost",
      component: React.lazy(() => import("./seasonalboost/SeasonalBoost")),
      name: "Bonus Seasonal Boost",
    },
    {
      path: "/bonus/parameters",
      component: React.lazy(() => import("./parameters/Parameters")),
      name: "Bonus Parameters",
    },
    {
      path: "/bonus/trafficsources",
      component: React.lazy(() => import("./trafficsources/TrafficSources")),
      name: "Source of Traffic",
    },
    {
      path: "/bonus/app-settings",
      component: React.lazy(() => import("./app-settings/AppSettings")),
      name: "App Settings",
    },
  ],
};
