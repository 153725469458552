import * as Actions from "./store/actions";

import { Card, CardBody, CardHeader, Col, Row, FormGroup } from "reactstrap";
import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import IpTable from "app/views/DbCalls/IpTable";
import { TapchampsUtils } from "@tapchamps";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";
import moment from "moment";

const tableName = "ip";

class Ip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, "days").startOf("day"),
      endDate: moment(),
      filterSegment: "xxx",
    };
  }
  componentDidMount = async () => {
    await this.props.dispatch(
      Actions.setDateRange(tableName, this.state.startDate, this.state.endDate)
    );
    this.props.fetch(tableName);
  };
  onSortChange(sortName, sortOrder) {
    this.props.setSort(
      tableName,
      sortName,
      sortOrder,
      this.state.filterWeek,
      this.state.filterDay
    );
  }
  onPageChange(page, sizePerPage) {
    this.props.setPaging(tableName, page, sizePerPage);
  }
  onSizePerPageList(sizePerPage) {}

  onFilterChange(filterObj) {
    this.props.setFilter(tableName, filterObj);
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  setFilterSegment = (state) => {
    this.props.setSegmentFilter(tableName, state);
  };

  render() {
    const { loading, isTableLoading } = this.props;
    if (loading) {
      return loading();
    }

    const canViewUser = TapchampsUtils.hasPermission(
      ["admin"],
      this.props.user.role
    );

    const {
      data,
      table: { totalDataSize, sizePerPage, currentPage },
    } = this.props;

    return (
      <div className="animated fadeIn">
        {canViewUser && (
          <>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    IP
                    <FormGroup className="float-right mb-0">
                      <DateRangePicker
                        isOutsideRange={() => false}
                        startDate={this.state.startDate}
                        startDateId="startDate"
                        endDate={this.state.endDate}
                        endDateId="endDate"
                        small
                        onDatesChange={({ startDate, endDate }) =>
                          this.setState({ startDate, endDate })
                        }
                        focusedInput={this.state.focusedInput}
                        onFocusChange={(focusedInput) => {
                          this.setState({ focusedInput });
                          if (focusedInput === null) {
                            setTimeout(() => {
                              this.props.dispatch(
                                Actions.setDateRange(
                                  tableName,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              );
                            }, 500);
                          }
                        }}
                        orientation={this.state.orientation}
                        openDirection={this.state.openDirection}
                      />
                    </FormGroup>
                  </CardHeader>
                  <CardBody>
                    {/* <Button
                      color="primary"
                      onClick={() => this.setFilterSegment("xxx")}
                      size="sm"
                      outline={!(segmentFilter === "xxx")}
                      className="mb-1 mr-1"
                    >
                      xxx
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => this.setFilterSegment("xxx.xxx")}
                      size="sm"
                      outline={!(segmentFilter === "xxx.xxx")}
                      className="mb-1 mr-1"
                    >
                      xxx.xxx
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => this.setFilterSegment("xxx.xxx.xxx")}
                      size="sm"
                      outline={!(segmentFilter === "xxx.xxx.xxx")}
                      className="mb-1 mr-1"
                    >
                      xxx.xxx.xxx
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => this.setFilterSegment("xxx.xxx.xxx.xxx")}
                      size="sm"
                      outline={!(segmentFilter === "xxx.xxx.xxx.xxx")}
                      className="mb-1 mr-1"
                    >
                      xxx.xxx.xxx.xxx
                    </Button> */}
                    {loading ? (
                      <div className="text-center">Loading data</div>
                    ) : false ? (
                      <div className="text-center">Empty</div>
                    ) : (
                      <IpTable
                        onSortChange={this.onSortChange.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        onSizePerPageList={this.onSizePerPageList.bind(this)}
                        data={Object.values(data)}
                        loading={isTableLoading}
                        totalDataSize={totalDataSize}
                        sizePerPage={sizePerPage}
                        currentPage={currentPage}
                        role={this.props.user.role}
                        onFilterChange={this.onFilterChange.bind(this)}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    table: state.dbcalls.table[tableName].table,
    data: state.dbcalls.table[tableName].data,
    isTableLoading: state.dbcalls.table[tableName].loading,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default withReducer(
  "dbcalls",
  reducer
)(connect(mapStateToProps, mapDispatchToProps)(Ip));
