import React from "react";
import { authRoles } from "app/auth";
export const NotificationsConfig = {
  auth: [...authRoles.admin, ...authRoles.notificationsViewer],
  routes: [
    {
      path: "/notifications/:notification_id",
      component: React.lazy(() => import("./notification/Notification")),
      name: "Notification",
    },
    {
      path: "/notifications",
      component: React.lazy(() => import("./notifications/Notifications")),
      name: "Notifications",
    },
  ],
};
