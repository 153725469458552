import * as Actions from "../actions";

const tablebaseState = {
  loading: false,
  table: {
    totalDataSize: 0,
    sizePerPage: 10,
    currentPage: 1,
    filter: {},
    sort: {},
  },
  data: {},
};

const tablebaseReducer = function (state = tablebaseState, action) {
  switch (action.payload.subtype) {
    case Actions.FETCH: {
      const {
        payload: { data, total },
      } = action;
      let final = {};
      data.forEach((element) => {
        final[element.id] = element;
        final[element.id].device_resolution = `${
          final[element.id].device_screen_width
        }x${final[element.id].device_screen_height}`;
      });
      return {
        ...state,
        loading: false,
        data: final,
        table: {
          ...state.table,
          totalDataSize: total,
        },
      };
    }
    case Actions.SET_LOADING: {
      const { payload } = action;
      return {
        ...state,
        loading: payload.value,
      };
    }
    case Actions.SET_FILTER: {
      const { payload } = action;
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
          currentPage: 1,
        },
      };
    }
    case Actions.SET_SORT: {
      const { payload } = action;
      return {
        ...state,
        table: {
          ...state.table,
          sort: payload,
        },
      };
    }
    case Actions.SET_PAGING: {
      const {
        payload: { page, sizePerPage },
      } = action;
      return {
        ...state,
        table: {
          ...state.table,
          sizePerPage,
          currentPage: page,
        },
      };
    }
    case Actions.CLEAN_ALL: {
      return {
        ...state,
        table: {
          ...state.table,
          sizePerPage: tablebaseState.table.sizePerPage,
          currentPage: tablebaseState.table.currentPage,
        },
        data: {},
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  markedusers: tablebaseState,
};

const tableReducer = function (state = initialState, action) {
  switch (action.type) {
    case "markedusers":
      return {
        ...state,
        markedusers: tablebaseReducer(state.markedusers, action),
      };
    default: {
      return state;
    }
  }
};

export default tableReducer;
