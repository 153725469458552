import { combineReducers } from "redux";
import files from "./files.reducer";
import countries from "./countries.reducer";
import table from "./table.reducer";

const reducer = combineReducers({
  files,
  countries,
  table,
});

export default reducer;
