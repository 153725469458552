import General from "./General";
import React from "react";
import { Redirect } from "react-router-dom";
import { authRoles } from "app/auth";

export const GeneralConfig = {
  auth: [...authRoles.admin, ...authRoles.generalViewer],
  routes: [
    {
      path: "/generalpages/privacy",
      component: General("Privacy Policy", "privacy"),
      name: "Privacy Policy",
    },
    {
      path: "/generalpages/terms",
      component: General("Terms & Conditions", "terms"),
      name: "Terms & Conditions",
    },
    {
      path: "/generalpages/faq",
      component: General("FAQ", "faq"),
      name: "FAQ",
    },
    {
      path: "/generalpages/howitworks",
      component: General("How it works", "howitworks"),
      name: "How it works",
    },
    {
      path: "/generalpages",
      component: () => <Redirect to="/generalpages/privacy" />,
      name: "General Pages",
    },
  ],
};
