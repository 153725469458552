import * as Actions from "../actions";

const initialState = {
  total: "",
  data: [],
  fields: [],
};

const revenuesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_REVENUES: {
      const { total, data, fields } = action.payload;
      return {
        ...state,
        total: total,
        data: [...data],
        fields: [...fields],
      };
    }
    case Actions.CLEAR_REVENUES: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default revenuesReducer;
