import * as Actions from "../actions";

const initialState = { countries: false };

const countriesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COUNTRIES: {
      const { payload } = action;
      return { countries: payload };
    }

    default: {
      return state;
    }
  }
};

export default countriesReducer;
