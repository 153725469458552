import React from "react";
import { authRoles } from "app/auth";

export const GaidBlockConfig = {
  auth: [...authRoles.admin, ...authRoles.gaidBlockViewer],
  routes: [
    {
      path: "/gaid-block",
      component: React.lazy(() => import("./gaidblock/gaidblock")),
      name: "GAID Block",
    },
  ],
};
