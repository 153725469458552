export const gameCategories = {
  1: "Arcade",
  2: "Puzzle",
  3: "Card",
  4: "Casual",
  5: "Racing",
  6: "Sports",
  7: "Action",
  8: "Adventure",
  9: "Board",
  10: "Casino",
  11: "Educational",
  12: "Music",
  13: "Role Playing",
  14: "Simulation",
  15: "Strategy",
  16: "Trivia",
  17: "Word",
};

export const boolFilter = {
  false: "NO",
  true: "YES",
};

export const boolFilterString = {
  No: "NO",
  Yes: "YES",
};

export const approveFilter = {
  false: "Not Approved",
  true: "Approved",
};

export const checkFraudStatusFilter = {
  1: "OK",
  2: "FRAUD",
  3: "ON HOLD",
  4: "MULTIPLE",
  5: "CLICKS",
  6: "C&M",
};

export const boolFilterShouldBan = {
  No: "No",
  Yes: "Yes",
  Checked: "Checked",
  Google: "Google",
};

export const gameBadgeIconTypes = {
  1: "social",
  2: "play",
  3: "chat",
  4: "star",
};

export const notificationType = {
  1: "Email",
  2: "Push",
  3: "Clevertap Event",
};

export const notificationStatus = {
  started: "Started",
  paused: "Paused",
  manual: "Manual",
  scheduled: "Scheduled",
  sent: "Sent",
};

export const notificationSchedule = {
  manual: "Manual",
  automatic: "Automatic",
};

export const notificationUsersGroup = {
  1: "Not active for more than X days",
  2: "Users who never played any games",
  3: "Users who only play X Game",
  4: "Users who have more than X units but didn’t use them",
  5: "Users who participated in current contest",
  6: "Users who played more than X minutes in a category",
  7: "All users",
  8: "Users who only play X Games (Count)",
  9: "Player has been inactive for >X minutes AND install day Y",
  10: "Player has been inactive for >X minutes AND install day Y AND No games installed",
  11: "Install greater or equal to X min ago",
  12: "Install greater or equal to X min ago AND inactive > Y minutes AND active < Z minutes",
  13: "Inactive for X minutes",
  14: "Users who participated in previous contest",
  15: "Users with more than X diamonds but didn't use them AND inactive > Y minutes",
  16: "Users who installed less than X games AND Install greater or equal to Y minutes ago",
  17: "User has just been banned automatically by the system",
  18: 'Users who play Game X and badge "User played on or after Day X after installation" is not completed already and due is today',
  19: 'Users who play any Game and badge "User played on or after Day X after installation" is not completed already and due is today',
  20: "People who installed more than X games",
  21: "People that played more than X min during last Y days",
  22: "People that made purchases within last X days",
  23: "People that redeemed rewards within last X days",
  24: "People who earned more than X Diamonds in last Y days",
  25: "People that made purchases within last X days in category Y",
  26: "When new game added send notification to the users who played the category of the game for more than X minutes. If they are payers boost the multiplier for Y minutes. Should be set only once!",
  27: "Send a notification and give a X hours boost increase to a game a user didn’t play for more than Y days and the game was installed Z days ago",
  28: "Notification when game become ultra boosted",
  29: "Users that made in app purchase in last X days ,offer them Y hours boost on all games",
};

export const notificationInterval = {
  1: "Every Day",
  2: "Every Week",
  7: "Every Two Weeks",
  3: "Every Month",
  4: "Every 3 Months",
  5: "Every 5 Minutes",
  6: "Every 10 Minutes",
};

export const dailyQuestPrizeTypes = {
  1: "Units",
  2: "Custom",
};

export const dailyQuestRuleTypes = {
  1: "Play during timeframe X minutes to earn Y diamonds",
  2: "Play X minutes every day during timeframe to earn Y diamonds",
  3: "Reach level X during timeframe and earn Y diamonds",
  4: "Make In-App purchase in game during timeframe and win extra Y diamonds",
};

export const contestWinTypes = {
  1: "Luck",
  2: "GXP",
  3: "Random from top X by GXP",
  4: "Player that makes most purchases",
  5: "Random player among top X by purchases",
};

export const contestPrizeTypes = {
  1: "Units",
  2: "Custom",
};

export const badgeTypes = {
  1: "Postback",
  2: "Computed",
};

export const badgeRuleTypes = {
  1: "Play X minutes a day for Y days",
  2: "Reach checkpoint X in game",
  3: "User played on or after Day X after installation",
  4: "Play X minutes",
};

export const gameTypes = {
  IAA: "IAA",
  IAP: "IAP",
};

export const contestTargetingTypes = {
  1: "Has Game",
};

export const contestRuleTypes = {
  1: "Get Y new followers",
  2: "Play Game for X minutes",
  3: "Install Game and reach checkpoint X",
  4: "Install Game and make in game purchase",
  5: "Install any new game and reach checkpoint X",
  6: "Invite Y friends to tapchamps",
  7: "Make In-App purchase in game",
  8: "Play X minutes per day for duration of the contest",
  9: "Play game for X minutes for Y days during timeframe",
};

export const contestRuleTypesIfTargeting = {
  2: "Play Targeted Game for X minutes",
  3: "Reach checkpoint X in Targeted Game",
  4: "Make in game purchase in Targeted Game",
};

export const raffleTargetingTypes = {
  1: "Has Game",
};

export const raffleRuleTypes = {
  1: "Ticket per all game",
  2: "Ticket per new games",
  3: "Ticket for supporter medal",
  4: "Ticket for total play time",
};

export const raffleRuleTypesIfTargeting = {
  3: "Ticket for supporter medal in targeted games",
  4: "Ticket for total play time in targeted games",
  5: "Tickets for playing X minutes",
};

export const bucketPeriods = {
  1: "Yesterday",
  2: "Today",
  3: "Last 7 Days",
  4: "Last Month",
  5: "This Month",
};

export const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

export const ISP_CONST = {
  banned: "banned",
  allowed: "allowed",
  normal: "normal",
};

export const smsVerificationStates = {
  required: "required",
  verified: "verified",
};

export const MAX_FILE_SIZE = 10 * 1024;

export const appThemes = {
  normal: "Normal",
  xmas: "Christmas",
};
