import AppWhitelist from "./AppWhitelist";
import { authRoles } from "app/auth";

export const AppWhitelistConfig = {
  auth: [...authRoles.admin],
  routes: [
    {
      path: "/appwhitelist",
      component: AppWhitelist,
      name: "App Whitelist",
    },
  ],
};
