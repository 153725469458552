import React from "react";

export const UserConfig = {
  auth: ["admin", "users-viewer"],
  routes: [
    {
      path: "/users/:id",
      component: React.lazy(() => import("./User")),
      name: "User",
    },
  ],
};
