import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, Spinner } from "reactstrap";
import React, { Component } from "react";

import moment from "moment";

function dateFormat(col) {
  if (col === undefined) {
    return null;
  }
  return moment(col).format("lll");
}

class InstallTable extends Component {
  constructor(props) {
    super(props);
    this.handlerClickCleanFiltered = this.handlerClickCleanFiltered.bind(this);
    this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
    this.buttonFormatter = this.buttonFormatter.bind(this);
  }

  handlerClickCleanFiltered(e) {
    this.refs.gameName.cleanFiltered();
    this.refs.link.cleanFiltered();
    this.refs.created_at.cleanFiltered();
    this.refs.created_at.cleanFiltered();
    this.refs.confirmed.cleanFiltered();
    this.refs.confirmedDate.cleanFiltered();
  }

  onAfterSaveCell(row, cellName, cellValue) {
    this.props.onSaveCell(row, cellName, cellValue);
  }

  buttonFormatter(cell, row) {
    return (
      <Button
        className="p-0"
        onClick={(e) => this.props.onUserClick(e, row)}
        color="link"
      >
        {row.username}
      </Button>
    );
  }

  redirectPathFormatter = (cell, row) => {
    if (cell === undefined) {
      return "";
    }
    let out = [];
    cell.forEach((item, index) => {
      out.push(<p key={index}>{item}</p>);
    });
    return <>{out}</>;
  };

  render() {
    return (
      <>
        <Button
          onClick={this.handlerClickCleanFiltered}
          size="sm"
          outline
          className="mb-1 mr-1"
        >
          clear filters
        </Button>
        <Button onClick={this.toggleExpand} size="sm" outline className="mb-1">
          Expand
        </Button>
        {this.props.loading === true && (
          <Spinner className="ml-2" color="primary" size="sm" />
        )}
        <BootstrapTable
          trClassName="customBootstrapTable"
          data={this.props.loading ? [] : this.props.data}
          remote={true}
          pagination={true}
          striped
          fetchInfo={{ dataTotalSize: this.props.totalDataSize }}
          options={{
            defaultSortName: "created_at",
            defaultSortOrder: "desc",
            onSearchChange: this.props.onSearchChange,
            clearSearch: true,
            onFilterChange: this.props.onFilterChange,
            onDeleteRow: this.props.onDeleteRow,
            onSortChange: this.props.onSortChange,
            sizePerPage: this.props.sizePerPage,
            onPageChange: this.props.onPageChange,
            sizePerPageList: [10, 20, 50, 100],
            page: this.props.currentPage,
            onSizePerPageList: this.props.onSizePerPageList,
          }}
        >
          <TableHeaderColumn dataField="id" isKey hidden>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn
            editable={false}
            ref="gameName"
            width="200px"
            dataField="gameName"
            filter={{ type: "TextFilter" }}
          >
            Game Name
          </TableHeaderColumn>

          <TableHeaderColumn
            editable={false}
            ref="username"
            width="120px"
            dataField="username"
            dataFormat={this.buttonFormatter}
            filter={{ type: "TextFilter" }}
            dataSort={false}
          >
            Username
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={false}
            ref="confirmed"
            width="120px"
            dataField="confirmed"
            dataSort={false}
          >
            Confirmed
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={false}
            ref="link"
            width="500px"
            dataField="link"
            dataSort={false}
            tdStyle={{ overflow: "scroll", textOverflow: "inherit" }}
          >
            Link
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={false}
            ref="redirectPath"
            width="500px"
            dataField="redirectPath"
            dataFormat={this.redirectPathFormatter}
            tdStyle={{ overflow: "scroll", textOverflow: "inherit" }}
          >
            Redirect Path
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={false}
            ref="click_id"
            width="310px"
            dataField="click_id"
            filter={{ type: "TextFilter" }}
            dataSort={false}
          >
            Click Id
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={false}
            ref="created_at"
            width="200px"
            dataField="created_at"
            dataFormat={dateFormat}
            dataSort={false}
          >
            Click Date
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={false}
            ref="confirmedDate"
            width="200px"
            dataField="confirmedDate"
            dataFormat={dateFormat}
            dataSort={false}
          >
            Postback Date
          </TableHeaderColumn>
        </BootstrapTable>
      </>
    );
  }
}

export default InstallTable;
