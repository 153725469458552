import React from "react";
import { authRoles } from "app/auth";
import { userPagesConfig } from "./user/userPagesConfig";

const UsersConfig = {
  auth: [...authRoles.admin, ...authRoles.usersViewer],
  routes: [
    {
      path: "/users",
      component: React.lazy(() => import("./users/Users")),
      name: "Users",
    },
    {
      path: "/bannedusers",
      component: React.lazy(() => import("./bannedusers/BannedUsers")),
      name: "Banned Users",
    },
    {
      path: "/users-score",
      component: React.lazy(() => import("./score/UsersScore")),
      name: "Users score",
    },
  ],
};

export const usersConfigs = [...userPagesConfig, UsersConfig];
