import { AdvertisersConfig } from "app/containers/Advertisers/AdvertisersConfig";
import { BoostsAndBonusesConfig } from "app/containers/BoostsAndBonuses/BoostsAndBonusesConfig";
import { AppWhitelistConfig } from "app/containers/AppWhitelist/AppWhitelistConfig";
import { AutoClickersConfig } from "app/containers/AutoClickers/AutoClickersConfig";
import { MarkedConfig } from "app/containers/Marked/MarkedConfig";
import { BonusConfig } from "app/containers/Bonus/BonusConfig";
import { BreakdownStatsConfig } from "app/containers/BreakdownStats/BreakdownStatsConfig";
import { FraudalyticsConfig } from "app/containers/Fraudalytics/FraudalyticsConfig";
import { CarriersConfig } from "app/containers/Carriers/CarriersConfig";
import { ContestsConfig } from "app/containers/Contests/ContestsConfig";
import { DailyQuestsConfig } from "app/containers/DailyQuests/DailyQuestsConfig";
import { FraudConfig } from "app/containers/Fraud/FraudConfig";
import { GameStatsConfig } from "app/containers/GameStats/GameStatsConfig";
import { GamesConfig } from "app/containers/Games/GamesConfig";
import { BoostedGamesConfig } from "app/containers/BoostedGames/BoostedGamesConfig";
import { CampaignsConfig } from "app/containers/Campaigns/CampaignsConfig";
import { CampaignEmailReportsConfig } from "app/containers/CampaignEmailReports/CampaignEmailReportsConfig";
import { GeneralConfig } from "app/containers/General/GeneralConfig";
import { InstallConfig } from "app/containers/Install/InstallConfig";
import { ReferalsConfig } from "app/containers/Referals/ReferalsConfig";
import { ContentRewardConfig } from "app/containers/ContentReward/ContentRewardConfig";
import { GaidBlockConfig } from "app/containers/GaidBlock/GaidBlockConfig";
import { GameWhitelistConfig } from "app/containers/GameWhitelistCompetitors/GameWhitelistConfig";
import { IspConfig } from "app/containers/Isp/IspConfig";
import { LoginConfig } from "app/containers/Login/LoginConfig";
import { LogoutConfig } from "app/containers/Logout/LogoutConfig";
import { NotificationsConfig } from "app/containers/Notifications/NotificationsConfig";
import { PermissionsConfig } from "app/containers/Permissions/PermissionsConfig";
import { QuotesConfig } from "app/containers/Quotes/QuotesConfig";
import React from "react";
import { RevenuesConfig } from "app/containers/Revenues/RevenuesConfig";
import { RewardsConfig } from "app/containers/Rewards/RewardsConfig";
import { TapchampsUtils } from "@tapchamps";
import { usersConfigs } from "app/containers/Users/UsersConfig";
import { DbCallsConfig } from "app/containers/DbCalls/DbCallsConfig";
import { ProfitConfig } from "app/containers/Profit/ProfitConfig";
import { RafflesConfig } from "../containers/Raffles/RafflesConfig";
import { GaidUserInfoConfig } from "app/containers/GaidUserInfo/GaidUserInfoConfig";
import { DailyQuestsStatsConfig } from "app/containers/DailyQuestsStats/DailyQuestsStatsConfig";

const routeConfigsGuest = [LoginConfig];

const routeConfigs = [
  ...usersConfigs,
  LogoutConfig,
  PermissionsConfig,
  AdvertisersConfig,
  BoostsAndBonusesConfig,
  RewardsConfig,
  RevenuesConfig,
  GamesConfig,
  BoostedGamesConfig,
  CampaignsConfig,
  CampaignEmailReportsConfig,
  BonusConfig,
  //SettingsConfig,
  ContestsConfig,
  DailyQuestsConfig,
  DailyQuestsStatsConfig,
  GeneralConfig,
  NotificationsConfig,
  FraudConfig,
  QuotesConfig,
  FraudalyticsConfig,
  BreakdownStatsConfig,
  GameStatsConfig,
  IspConfig,
  InstallConfig,
  AppWhitelistConfig,
  AutoClickersConfig,
  MarkedConfig,
  CarriersConfig,
  ReferalsConfig,
  ContentRewardConfig,
  DbCallsConfig,
  ProfitConfig,
  RafflesConfig,
  GaidBlockConfig,
  GameWhitelistConfig,
  GaidUserInfoConfig,
];

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  ...TapchampsUtils.generateRoutesFromConfigs(routeConfigsGuest, null),
  ...TapchampsUtils.generateRoutesFromConfigs(routeConfigs, ["admin", "staff"]),
  { path: "/", exact: true, name: "Home", auth: ["admin", "staff"] },
  {
    path: "/overview",
    name: "Stats Overview",
    auth: ["admin", "staff"],
    component: React.lazy(() =>
      import("app/containers/StatsOverview/StatsOverview")
    ),
  },
];

export default routes;
