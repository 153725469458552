import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";

import {
  BootstrapTable,
  InsertButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { Button, Spinner } from "reactstrap";
import React, { Component } from "react";

import { boolFilter } from "@tapchamps/Types";

class CarriersTable extends Component {
  constructor(props) {
    super(props);
    this.handlerClickCleanFiltered = this.handlerClickCleanFiltered.bind(this);
    this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
    this.insertBtn = this.insertBtn.bind(this);
  }

  handlerClickCleanFiltered(e) {
    this.refs.carrier.cleanFiltered();
    this.refs.countrycode.cleanFiltered();
    this.refs.count.cleanFiltered();
    this.refs.whitelist.cleanFiltered();
    this.refs.vpnDialogCount.cleanFiltered();
  }

  onAfterSaveCell(row, cellName, cellValue) {
    this.props.onSaveCell(row, cellName, cellValue);
  }

  insertBtn() {
    return <InsertButton onClick={this.props.onInsertBtnClick} />;
  }

  render() {
    const valuesForSelect = Object.keys(boolFilter).map((o) => ({
      value: o,
      text: boolFilter[o],
    }));

    return (
      <>
        <Button
          onClick={this.handlerClickCleanFiltered}
          size="sm"
          outline
          className="mb-1"
        >
          clear filters
        </Button>
        {this.props.loading === true && (
          <Spinner className="ml-2" color="primary" size="sm" />
        )}
        <BootstrapTable
          trClassName="customBootstrapTable"
          data={this.props.loading ? [] : this.props.data}
          remote={true}
          pagination={true}
          cellEdit={{
            mode: "click",
            afterSaveCell: this.onAfterSaveCell,
          }}
          striped
          deleteRow={true}
          selectRow={{ mode: "checkbox" }}
          insertRow={true}
          fetchInfo={{ dataTotalSize: this.props.totalDataSize }}
          options={{
            insertBtn: this.insertBtn,
            defaultSortName: "app",
            defaultSortOrder: "asc",
            onSearchChange: this.props.onSearchChange,
            clearSearch: true,
            onFilterChange: this.props.onFilterChange,
            onDeleteRow: this.props.onDeleteRow,
            onSortChange: this.props.onSortChange,
            sizePerPage: this.props.sizePerPage,
            onPageChange: this.props.onPageChange,
            sizePerPageList: [10, 20, 50, 100],
            page: this.props.currentPage,
            onSizePerPageList: this.props.onSizePerPageList,
          }}
        >
          <TableHeaderColumn dataField="id" isKey hidden>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn
            editable={false}
            ref="carrier"
            width="200px"
            dataField="carrier"
            dataSort={false}
            filter={{ type: "TextFilter" }}
          >
            Carrier
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={false}
            ref="countrycode"
            width="120px"
            dataField="countrycode"
            dataSort={false}
            // filter={{ type: "TextFilter" }}
          >
            Countrycode
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={false}
            ref="count"
            width="120px"
            dataField="count"
            dataSort={false}
            // filter={{
            //   type: "NumberFilter",
            //   delay: 1000,
            //   numberComparators: ["=", ">", "<="],
            // }}
          >
            Number Of Installs
          </TableHeaderColumn>
          <TableHeaderColumn
            ref="vpnDialogCount"
            width="120px"
            dataField="vpnDialogCount"
            dataSort={true}
            filter={{ type: "NumberFilter", delay: 2000 }}
          >
            VPN Dialog Count
          </TableHeaderColumn>
          <TableHeaderColumn
            ref="whitelist"
            width="120px"
            dataField="whitelist"
            dataSort={false}
            filter={{
              type: "SelectFilter",
              options: boolFilter,
              delay: 2000,
            }}
            editable={{
              type: "select",
              options: { values: valuesForSelect },
            }}
          >
            Whitelist
          </TableHeaderColumn>
        </BootstrapTable>
      </>
    );
  }
}

export default CarriersTable;
