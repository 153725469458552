import React from "react";
import { authRoles } from "app/auth";

export const DailyQuestsStatsConfig = {
  auth: [...authRoles.admin, ...authRoles.dailyQuestsViewer],
  routes: [
    {
      path: "/dailyQuestsStats",
      component: React.lazy(() =>
        import("./dailyQuestsStats/DailyQuestsStats")
      ),
      name: "DailyQuestsStats",
    },
  ],
};
