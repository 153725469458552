import * as Yup from "yup";
import * as authActions from "app/auth/store/actions";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import FormHelper from "app/helpers/form-helper";

const validationSchema = function (values) {
  return Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    password: Yup.string()
      .min(6, `Password has to be at least ${6} characters!`)
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/,
        "Password must contain: numbers, uppercase and lowercase letters\n"
      )
      .required("Password is required"),
  });
};

let submitActions = null;
function Login(props) {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);

  useEffect(() => {
    if (login.error && (login.error.email || login.error.password)) {
      const { setSubmitting, setErrors } = submitActions;
      setSubmitting(false);
      setErrors(login.error);
    }
  }, [login.error]);

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values, actions) => {
    submitActions = actions;
    dispatch(authActions.submitLogin(values));
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="4">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Formik
                    initialValues={initialValues}
                    validate={FormHelper.validate(validationSchema)}
                    onSubmit={onSubmit}
                    render={({
                      values,
                      errors,
                      touched,
                      status,
                      dirty,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      handleReset,
                      setTouched,
                    }) => (
                      <Form onSubmit={handleSubmit} noValidate>
                        <h1>Login</h1>
                        <p className="text-muted">Sign In to your account</p>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            id="email"
                            placeholder="Email"
                            valid={!errors.email}
                            invalid={touched.email && !!errors.email}
                            required
                            autoFocus={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <FormFeedback>{errors.email}</FormFeedback>
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            id="password"
                            type="password"
                            placeholder="Password"
                            valid={!errors.password}
                            invalid={touched.password && !!errors.password}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <FormFeedback>{errors.password}</FormFeedback>
                        </InputGroup>
                        <Row>
                          <Col xs="6">
                            <Button
                              type="submit"
                              color="primary"
                              className="px-4"
                              disabled={isSubmitting}
                            >
                              Login
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  />
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
