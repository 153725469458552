import "./react_dates_overrides.css";

import * as Actions from "./store/actions";

import moment from "moment";
import { Card, CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { DateRangePicker } from "react-dates";
import { TapchampsUtils } from "@tapchamps";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

function dateFormat(col, row) {
  return moment(col.value).format("ddd D.M");
}
class Profit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      startDate: null,
      endDate: null,
    };
  }
  componentDidMount() {
    const {
      profit: { startDate, endDate },
    } = this.props;
    this.setState({ startDate, endDate }, () => {
      this.props.dispatch(Actions.getAverageUnits());
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  render() {
    const { loading } = this.props;
    if (loading) {
      return loading();
    }

    const canView = TapchampsUtils.hasPermission(
      ["profit-viewer"],
      this.props.user.role
    );

    const {
      profit: { averageUnits, loading: profit_loading },
    } = this.props;

    console.log("average units", averageUnits, profit_loading);

    return (
      canView && (
        <div className="animated fadeIn">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <FormGroup className="float-right mb-0">
                    <DateRangePicker
                      isOutsideRange={() => false}
                      startDate={this.state.startDate}
                      startDateId="startDate"
                      endDate={this.state.endDate}
                      endDateId="endDate"
                      small
                      minimumNights={0}
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                        if (focusedInput === null) {
                          setTimeout(() => {
                            this.props.dispatch(
                              Actions.setDateRangeAvgUnits(
                                this.state.startDate,
                                this.state.endDate
                              )
                            );
                          }, 0);
                        }
                      }}
                      orientation={this.state.orientation}
                      openDirection={this.state.openDirection}
                    />
                  </FormGroup>
                </CardHeader>
                <CardBody>
                  {profit_loading ? (
                    <Spinner />
                  ) : (
                    <BootstrapTable
                      trClassName="customBootstrapTable"
                      data={loading ? [] : averageUnits}
                      remote={true}
                      striped
                    >
                      <TableHeaderColumn
                        row="0"
                        rowSpan="2"
                        width="200px"
                        dataField="created_at_date"
                        dataFormat={dateFormat}
                        isKey
                      >
                        Date
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="0"
                        colSpan="3"
                        headerAlign="center"
                      >
                        All Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataAlign="right"
                        dataField="units"
                        dataFormat={(col) => col.toLocaleString()}
                      >
                        Units
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataAlign="right"
                        dataField="users"
                        dataFormat={(col) => col.toLocaleString()}
                      >
                        Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="avgPerUser"
                        dataAlign="right"
                        dataFormat={(col) => Math.floor(col)}
                      >
                        Avg Units / User
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="0"
                        colSpan="3"
                        headerAlign="center"
                      >
                        New Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="newUsersUnits"
                        dataFormat={(col) => col.toLocaleString()}
                        dataAlign="right"
                      >
                        Units
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="newUsers"
                        dataFormat={(col) => col.toLocaleString()}
                        dataAlign="right"
                      >
                        Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="avgPerNewUser"
                        dataFormat={(col) => Math.floor(col)}
                        dataAlign="right"
                      >
                        Avg Units / User
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="0"
                        colSpan="3"
                        headerAlign="center"
                      >
                        Old Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="oldUsersUnits"
                        dataFormat={(col) => col.toLocaleString()}
                        dataAlign="right"
                      >
                        Units
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="oldUsers"
                        dataFormat={(col) => col.toLocaleString()}
                        dataAlign="right"
                      >
                        Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="avgPerOldUser"
                        dataFormat={(col) => Math.floor(col)}
                        dataAlign="right"
                      >
                        Avg Units / User
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="0"
                        colSpan="3"
                        headerAlign="center"
                      >
                        Unlocked Revenue Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="revenueUnlockedUnits"
                        dataFormat={(col) => col.toLocaleString()}
                        dataAlign="right"
                      >
                        Units
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="revenueUnlockedUsers"
                        dataFormat={(col) => col.toLocaleString()}
                        dataAlign="right"
                      >
                        Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="avgPerRevUnlockedUser"
                        dataFormat={(col) => Math.floor(col)}
                        dataAlign="right"
                      >
                        Avg Units / User
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="0"
                        colSpan="3"
                        headerAlign="center"
                      >
                        Locked Revenue Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="revenueLockedUnits"
                        dataFormat={(col) => col.toLocaleString()}
                        dataAlign="right"
                      >
                        Units
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="revenueLockedUsers"
                        dataFormat={(col) => col.toLocaleString()}
                        dataAlign="right"
                      >
                        Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        row="1"
                        width="150px"
                        dataField="avgPerRevLockedUser"
                        dataFormat={(col) => Math.floor(col)}
                        dataAlign="right"
                      >
                        Avg Units / User
                      </TableHeaderColumn>
                    </BootstrapTable>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profit: state.profit.profit,
    user: state.auth.user,
  };
};

export default withReducer("profit", reducer)(connect(mapStateToProps)(Profit));
