import React from "react";
import { authRoles } from "app/auth";
export const BoostedGamesConfig = {
  auth: [...authRoles.admin, ...authRoles.gamesViewer],
  routes: [
    {
      path: "/boostedgames",
      component: React.lazy(() => import("./games/BoostedGames")),
      name: "BoostedGames",
    },
  ],
};
