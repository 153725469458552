import { apiSagaRequest, showMessage } from "app/store/actions/tapchamps";

import axios from "axios";

export const FETCH = "[TABLE] FETCH";
export const CLEAN_ALL = "[TABLE] CLEAN ALL";
export const SET_FILTER = "[TABLE] SET FILTER";
export const SET_SORT = "[TABLE] SET SORT";
export const SET_PAGING = "[TABLE] SET PAGING";
export const UPDATE_CELL = "[TABLE] UPDATE CELL";
export const DELETE_ROWS = "[TABLE] DELETE ROWS";
export const CREATE_ROW = "[TABLE] CREATE ROW";
export const SET_EXPANDED_ROW = "[TABLE] SET EXPANDED ROW";
export const SET_LOADING = "[TABLE] SET LOADING";

export function setPaging(type, page, sizePerPage) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { page, sizePerPage, subtype: SET_PAGING },
    });
    dispatch(fetch(type));
  };
}

export function setFilter(type, filter) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { ...filter, subtype: SET_FILTER },
    });
    dispatch(fetch(type));
  };
}

export function setSort(type, sortName, sortOrder) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { sortName, sortOrder, subtype: SET_SORT },
    });
    dispatch(fetch(type));
  };
}

export function clean(type) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { subtype: CLEAN_ALL },
    });
  };
}

export function setLoading(type, value) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { value, subtype: SET_LOADING },
    });
  };
}

export function fetch(type) {
  return (dispatch, getState) => {
    const {
      isp: {
        table: {
          [type]: { table },
        },
      },
    } = getState();

    dispatch(setLoading(type, true));
    return dispatch(
      apiSagaRequest("TABLE_REQUEST", {
        method: "post",
        url: "/api/isp/" + type,
        data: { ...table },
      })
    ).then(
      (response) =>
        dispatch({
          type: type,
          payload: { ...response.data, subtype: FETCH },
        }),
      (e) => {
        dispatch(
          showMessage({
            message: e.message ? e.message : e.response.data.errors.message,
            type: "error",
          })
        );
      }
    );
  };
}

export function add(type, values, actions) {
  return (dispatch, getState) => {
    const request = axios.post("/api/isp/" + type + "/add", values);
    return request
      .then((response) => {
        actions.setSubmitting(false);
        dispatch(showMessage({ message: `ISP has been saved` }));
        return dispatch({
          type: type,
          payload: { row: response.data, subtype: UPDATE_CELL },
        });
      })
      .catch((e) => {
        actions.setSubmitting(false);
        dispatch(
          showMessage({
            message: e.response.data.errors.message,
            type: "error",
          })
        );
      });
  };
}

export function updateCell(type, row, cellName, cellValue) {
  return (dispatch) => {
    if (row[cellName] === cellValue) {
      return;
    }
    const updatedRow = {
      ...row,
      [cellName]: cellValue,
    };
    const request = axios.post(`/api/isp/${type}/edit`, updatedRow);
    return request.then((response) => {
      dispatch(showMessage({ message: `Value has been saved` }));
      return dispatch({
        type: type,
        payload: { row: response.data, subtype: UPDATE_CELL },
      });
    });
  };
}

export function deleteRows(type, rowArr) {
  return (dispatch) => {
    const request = axios.post(`/api/isp/${type}/delete`, rowArr);
    return request
      .then((response) => {
        dispatch(showMessage({ message: `Deleted` }));
        return dispatch({
          type: type,
          payload: { row: response.data, subtype: DELETE_ROWS },
        });
      })
      .catch((e) => {
        dispatch(
          showMessage({
            message: e.response.data.errors.message,
            type: "error",
          })
        );
      });
  };
}
