import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

class ResolutionTable extends Component {
  constructor(props) {
    super(props);
    this.handlerClickCleanFiltered = this.handlerClickCleanFiltered.bind(this);
  }

  handlerClickCleanFiltered(e) {
    this.refs.created_at.cleanFiltered();
    this.refs.count.cleanFiltered();
  }

  users(cell, row) {
    let users = [];
    cell.forEach((item, index) => {
      if (index + 1 === cell.length) {
        users.push(<Link to={"/users/" + item.id}>{item.username}</Link>);
      } else {
        users.push(
          <Link to={"/users/" + item.id}>{item.username + ", "}</Link>
        );
      }
    });
    return <>{users}</>;
  }

  render() {
    return (
      <>
        {this.props.loading === true && (
          <Spinner className="ml-2" color="primary" size="sm" />
        )}
        <BootstrapTable
          trClassName="customBootstrapTable"
          data={this.props.loading ? [] : this.props.data}
          remote={true}
          pagination={true}
          striped
          fetchInfo={{ dataTotalSize: this.props.totalDataSize }}
          options={{
            defaultSortName: "total",
            defaultSortOrder: "desc",
            onSearchChange: this.props.onSearchChange,
            clearSearch: true,
            onFilterChange: this.props.onFilterChange,
            onDeleteRow: this.props.onDeleteRow,
            onSortChange: this.props.onSortChange,
            sizePerPage: this.props.sizePerPage,
            onPageChange: this.props.onPageChange,
            sizePerPageList: [5, 10, 15],
            page: this.props.currentPage,
            onSizePerPageList: this.props.onSizePerPageList,
          }}
        >
          <TableHeaderColumn
            isKey
            editable={false}
            ref="_id"
            //width="800px"
            dataField="_id"
            filter={{ type: "TextFilter", delay: 2000 }}
            dataSort={false}
          >
            Resolution
          </TableHeaderColumn>

          <TableHeaderColumn
            editable={false}
            ref="total"
            dataField="total"
            tdStyle={{ whiteSpace: "normal" }}
            // filter={{ type: "NumberFilter", delay: 2000 }}
            dataSort={false}
          >
            Total users
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={false}
            ref="users"
            dataField="users"
            dataFormat={this.users}
            tdStyle={{ whiteSpace: "normal" }}
            // filter={{ type: "TextFilter", delay: 2000 }}
          >
            Users
          </TableHeaderColumn>
        </BootstrapTable>
      </>
    );
  }
}

export default ResolutionTable;
