import GameStats from "./GameStats";
import { authRoles } from "app/auth";

export const GameStatsConfig = {
  auth: [...authRoles.admin],
  routes: [
    {
      path: "/gamestats",
      component: GameStats,
      name: "Game Stats",
    },
  ],
};
