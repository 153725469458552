import cfg from "./cfg.reducer";
import { combineReducers } from "redux";
import dialog from "./dialog.reducer";
import message from "./message.reducer";
import routes from "./routes.reducer";

const fuseReducers = combineReducers({
  message,
  dialog,
  routes,
  cfg,
});

export default fuseReducers;
