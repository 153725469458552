import { toast } from "react-toastify";

export const HIDE_MESSAGE = "[MESSAGE] CLOSE";
export const SHOW_MESSAGE = "[MESSAGE] SHOW";

export function hideMessage() {
  return {
    type: HIDE_MESSAGE,
  };
}

export function showMessage(options) {
  const { message, type } = options;
  if (type === "warn") {
    toast.warn(message, { options });
  } else if (type === "error") {
    toast.error(message, { options });
  } else {
    toast.success(message, { options });
  }

  return {
    type: SHOW_MESSAGE,
    options,
  };
}
