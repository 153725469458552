import "quill/dist/quill.snow.css";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input,
} from "reactstrap";
import React, { Component } from "react";

import ReactQuill from "react-quill";

class TextEditors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.contents[this.props.defaultCountry],
      country: false,
    }; // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        ["clean"], // remove formatting button
      ],
    };
  }

  componentDidMount() {
    this.setState({ country: this.props.defaultCountry });
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  onSubmit() {
    const { handleSubmit } = this.props;
    handleSubmit(this.state.text, this.state.country);
  }

  handleCountryChange = (e) => {
    this.setState({
      country: e.target.value,
      text: this.props.contents[e.target.value],
    });
  };

  render() {
    if (this.state.country === false) {
      return (
        <div className="animated fadeIn pt-1 text-center">
          <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
      );
    }

    const { title } = this.props;
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col xs="8">{title}</Col>
            <Col xs="4">
              <Input
                type="select"
                id="country"
                name="country"
                placeholder="Country"
                onChange={this.handleCountryChange}
                value={this.state.country}
              >
                {this.props.countries.map((data, i) => {
                  return (
                    <option key={i} value={data.countrycode}>
                      {data.countrycode}
                      {data.default === true && " (default)"}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <ReactQuill
            value={this.state.text}
            modules={this.modules}
            onChange={this.handleChange}
          />
          <Row>
            <Col xl className="mt-3 mb-xl-0">
              <Button
                size="lg"
                outline
                block
                color="primary"
                onClick={this.onSubmit}
              >
                Save {this.state.country.toUpperCase()}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default TextEditors;
