import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";

import PropTypes from "prop-types";

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
};

class WidgetGameAgeBuckets extends Component {
  render() {
    const { data, loading, selectedEventnames, groupBy } = this.props;

    return (
      <>
        {loading === true ? (
          <Spinner color="primary" size="md" />
        ) : (
          <Table responsive size="sm">
            <thead>
              {selectedEventnames.length > 0 ? (
                <tr>
                  <th colSpan={12}>STATS</th>
                  <th colSpan={selectedEventnames.length}>EVENT NAMES</th>
                </tr>
              ) : null}
              <tr>
                <th>{groupBy === 2 ? "Date" : "Gender"}</th>
                <th>
                  {groupBy === 0
                    ? "Age Bucket"
                    : groupBy === 1
                    ? "OS Version"
                    : groupBy === 2
                    ? "Date"
                    : ""}
                </th>
                <th>Installs</th>
                <th>Average Total Play Time (m)</th>
                <th>Average Number of Times Played</th>
                <th>Average Number of Sessions</th>
                <th>Average Game Level</th>
                <th>Retention D0 (%)</th>
                <th>Retention D1 (%)</th>
                <th>Retention D2 (%)</th>
                <th>Retention D3 (%)</th>
                <th>Retention D4 (%)</th>
                <th>Retention D5 (%)</th>
                <th>Retention D6 (%)</th>
                <th>Retention D7 (%)</th>
                <th>Purchases</th>
                <th>% of People who purchased</th>
                {selectedEventnames.map((name) => (
                  <th key={name}>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).map((bucketKey) => {
                const d = data[bucketKey];
                const count = d.length;
                return d.map((row, i) => {
                  const {
                    r_retention_ratio_day_0,
                    r_retention_ratio_day_1,
                    r_retention_ratio_day_2,
                    r_retention_ratio_day_3,
                    r_retention_ratio_day_4,
                    r_retention_ratio_day_5,
                    r_retention_ratio_day_6,
                    r_retention_ratio_day_7,
                    bracket,
                    nop,
                    atpt,
                    anotp,
                    anos,
                    agl,
                    p,
                    pop,
                  } = row;
                  return (
                    <tr key={`${bucketKey}-${i}`}>
                      {i === 0 ? (
                        <td rowSpan={count}>
                          <b>{bucketKey}</b>
                        </td>
                      ) : null}
                      <td>{bracket}</td>
                      <td>{nop}</td>
                      <td>{atpt}</td>
                      <td>{anotp}</td>
                      <td>{anos}</td>
                      <td>{agl}</td>
                      <td>{r_retention_ratio_day_0}</td>
                      <td>{r_retention_ratio_day_1}</td>
                      <td>{r_retention_ratio_day_2}</td>
                      <td>{r_retention_ratio_day_3}</td>
                      <td>{r_retention_ratio_day_4}</td>
                      <td>{r_retention_ratio_day_5}</td>
                      <td>{r_retention_ratio_day_6}</td>
                      <td>{r_retention_ratio_day_7}</td>
                      <td>{p}</td>
                      <td>{pop}</td>
                      {selectedEventnames.map((e) => (
                        <td key={e}>{row[`e_${e}`]}</td>
                      ))}
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        )}
      </>
    );
  }
}

WidgetGameAgeBuckets.propTypes = propTypes;

export default WidgetGameAgeBuckets;
