import { apiSagaRequest, showMessage } from "app/store/actions/tapchamps";

import _ from "lodash";
import axios from "axios";

export const GET_BUCKETS = "[BREAKDOWNSTATS] GET BUCKETS";
export const SET_LOADING = "[BREAKDOWNSTATS] SET LOADING";
export const GET_GAMES_FOR_SELECT = "[BREAKDOWNSTATS] GET GAMES FOR SELECT";
export const GET_GAMES_EVENTS_FOR_SELECT =
  "[BREAKDOWNSTATS] GET GAMES EVENTS FOR SELECT";
export const GET_COUNTRIES_FOR_SELECT = "[BREAKDOWNSTATS] GET COUNTRIES";
export const SET_DATERANGE = "[BREAKDOWNSTATS] SET DATERANGE";

export function setDateRange(gameid, eventname, groupby, startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: SET_DATERANGE,
      payload: { startDate, endDate },
    });
    dispatch(getBuckets(gameid, eventname, groupby));
  };
}

export function getGamesEventsForSelect(gameid) {
  const params = { onlyActiveGames: 1, sort: "name" };
  if (gameid !== undefined && gameid !== "") {
    params.gameid = gameid;
  }
  const request = axios.get("/api/games/events/forselect", {
    params,
  });
  return (dispatch) =>
    request.then((response) => {
      dispatch({
        type: GET_GAMES_EVENTS_FOR_SELECT,
        payload: response.data,
      });
    });
}

export function getGamesForSelect() {
  const request = axios.get("/api/games/forselect", {
    params: { onlyActiveGames: 1, sort: "name" },
  });
  return (dispatch) =>
    request.then((response) => {
      dispatch({
        type: GET_GAMES_FOR_SELECT,
        payload: response.data,
      });
    });
}

export function getCountriesForSelect() {
  const request = axios.get(`/api/countries/allactive`);
  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: GET_COUNTRIES_FOR_SELECT,
        payload: response,
      });
    });
  };
}

export function setLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: loading,
    });
  };
}

export function sendReportSubmit(
  gameid,
  eventnames,
  groupby,
  email,
  actions,
  countryCode
) {
  return (dispatch, getState) => {
    const {
      breakdownstats: {
        agebuckets: { startDate, endDate },
      },
    } = getState();
    if (startDate === null || endDate === null) {
      return;
    }
    const dates = {
      startDate: startDate.utc().startOf("day").unix(),
      endDate: endDate.utc().startOf("day").unix(),
    };
    const params = { ...dates };
    if (gameid !== undefined && gameid !== "") {
      params.gameid = gameid;
    }
    if (groupby !== undefined && groupby !== "") {
      params.groupby = groupby;
    }
    if (countryCode !== undefined && countryCode !== "") {
      params.countryCode = countryCode;
    }
    if (
      eventnames !== undefined &&
      eventnames !== "" &&
      eventnames.length > 0
    ) {
      params.eventnames = _.join(eventnames, ",");
    }
    params.email = email;
    const request = axios.get(
      groupby === 2 ? "/api/stats/bydate/full" : "/api/stats/buckets/full",
      { params }
    );

    request.then(
      (response) => {
        if (response.data.error) {
          dispatch(showMessage({ message: response.data.error, type: "warn" }));
          return;
        }
        actions.resetForm();
        dispatch(showMessage({ message: `Report scheduled to ${email}` }));
      },
      () => {
        dispatch(showMessage({ message: "Report error", type: "warn" }));
        actions.setSubmitting(false);
      }
    );
  };
}
export function getBuckets(gameid, eventnames, groupby, countryCode) {
  return (dispatch, getState) => {
    const {
      breakdownstats: {
        agebuckets: { startDate, endDate },
      },
    } = getState();
    if (startDate === null || endDate === null) {
      return;
    }
    const dates = {
      startDate: startDate.utc().startOf("day").unix(),
      endDate: endDate.utc().startOf("day").unix(),
    };
    const params = { ...dates };
    if (gameid !== undefined && gameid !== "") {
      params.gameid = gameid;
    }
    if (groupby !== undefined && groupby !== "") {
      params.groupby = groupby;
    }
    if (countryCode !== undefined && countryCode !== "") {
      params.countryCode = countryCode;
    }
    if (
      eventnames !== undefined &&
      eventnames !== "" &&
      eventnames.length > 0
    ) {
      params.eventnames = _.join(eventnames, ",");
    }

    dispatch(setLoading(true));
    dispatch(
      apiSagaRequest("API_BUCKETS_REQUEST", {
        params,
        method: "get",
        url: groupby === 2 ? "/api/stats/bydate" : "/api/stats/buckets",
      })
    ).then(
      (response) => {
        if (response.data.error) {
          dispatch(
            showMessage({ message: response.data.error, type: "error" })
          );
          return;
        }
        dispatch({
          type: GET_BUCKETS,
          payload: response.data,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}
