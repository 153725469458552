import Carriers from "./Carriers";
import { authRoles } from "app/auth";

export const CarriersConfig = {
  auth: [...authRoles.admin],
  routes: [
    {
      path: "/carriers",
      component: Carriers,
      name: "Carriers",
    },
  ],
};
