import "./App.scss";
import "./AxiosEndpoint";

import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";

import AppContext from "./AppContext";
import { Auth } from "./auth";
import Provider from "react-redux/es/components/Provider";
import { TapchampsAuthorization } from "@tapchamps";
import history from "@history";
import navigation from "./configs/navigationConfig";
import routes from "./configs/routesConfig";
import store from "./store";

//import "@fake-db";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() =>
  import("app/containers/DefaultLayout/DefaultLayout")
);
const Login = React.lazy(() => import("app/containers/Login/Login"));
const TwoStepAuth = React.lazy(() =>
  import("app/containers/TwoStepAuth/TwoStepsAuth")
);
// Pages
const Page404 = React.lazy(() => import("./views/Pages/Page404/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500/Page500"));

class App extends Component {
  render() {
    return (
      <AppContext.Provider
        value={{
          routes,
          navigation,
        }}
      >
        <Provider store={store}>
          <Auth>
            <Router history={history}>
              <React.Suspense fallback={loading()}>
                <TapchampsAuthorization>
                  <Switch>
                    <Route
                      exact
                      path="/login"
                      name="Login Page"
                      render={(props) => <Login {...props} />}
                    />
                    <Route
                      exact
                      path="/twostepsauth"
                      name="Two Factor Page"
                      render={(props) => <TwoStepAuth {...props} />}
                    />
                    <Route
                      exact
                      path="/404"
                      name="Page 404"
                      render={(props) => <Page404 {...props} />}
                    />
                    <Route
                      exact
                      path="/500"
                      name="Page 500"
                      render={(props) => <Page500 {...props} />}
                    />
                    <Route
                      path="/"
                      name="Home"
                      render={(props) => <DefaultLayout {...props} />}
                    />
                  </Switch>
                </TapchampsAuthorization>
              </React.Suspense>
            </Router>
          </Auth>
        </Provider>
      </AppContext.Provider>
    );
  }
}

export default App;
