import React from "react";
import { authRoles } from "app/auth";

export const DailyQuestsConfig = {
  auth: [...authRoles.admin, ...authRoles.dailyQuestsViewer],
  routes: [
    {
      path: "/dailyquests/new",
      component: React.lazy(() => import("./dailyQuest/DailyQuest")),
      name: "DailyQuest",
    },
    {
      path: "/dailyquests/:dailyQuest_id",
      component: React.lazy(() => import("./DailyQuestTabs")),
      name: "DailyQuest",
    },
    {
      path: "/dailyquests",
      component: React.lazy(() => import("./dailyQuests/DailyQuests")),
      name: "DailyQuests",
    },
  ],
};
