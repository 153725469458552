import Login from "./Login";
import { authRoles } from "app/auth";

export const LoginConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/login",
      component: Login,
      name: "Login Page",
    },
  ],
};
