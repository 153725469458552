import React from "react";
import { authRoles } from "app/auth";

export const QuotesConfig = {
  auth: authRoles.admin,
  routes: [
    {
      path: "/quotes",
      component: React.lazy(() => import("./quotes/Quotes")),
      name: "Quotes",
    },
  ],
};
