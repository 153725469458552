import axios from "axios";
import { apiSagaRequest } from "app/store/actions";
import { showMessage } from "app/store/actions/tapchamps";
import moment from "moment";

export const SET_FRAUDALYTICS_TAB = "[FRAUDALYTICS] SET TAB";
export const CLEAN_FRAUDALYTICS = "[FRAUDALYTICS] CLEAN FRAUD";
export const GET_STATS = "[FRAUDALYTICS] GET STATS";
export const GET_REQUESTS = "[FRAUDALYTICS] GET REQUESTS";
export const GET_USERS = "[FRAUDALYTICS] GET USERS";
export const SET_LOADING = "[FRAUDALYTICS] SET LOADING";

export function getStats() {
  const request = axios.get("/api/stats/stats");

  return (dispatch) =>
    request.then((response) => {
      if (response.data.error) {
        dispatch(showMessage({ message: response.data.error, type: "error" }));
        return;
      }
      //LOAD users resolutions
      dispatch(getUsers("users"));
      dispatch({
        type: GET_STATS,
        payload: response.data,
      });
    });
}

export function getUsers(type) {
  return (dispatch, getState) => {
    const table = {
      totalDataSize: 0,
      sizePerPage: 10,
      currentPage: 1,
      filter: {
        subtype: "[TABLE] SET FILTER",
        dateFilter: true,
      },
      sort: {},
    };
    return dispatch(
      apiSagaRequest("TABLE_REQUEST", {
        method: "post",
        url: "/api/" + type,
        data: { ...table },
      })
    ).then((response) => {
      console.log("[info] res2: ", response.data);
      dispatch({
        type: GET_USERS,
        payload: response.data,
      });
    });
  };
}

export function fetchRequests(date) {
  return (dispatch, getState) => {
    let doubleDate = false;

    //START LOADING
    dispatch({
      type: SET_LOADING,
      payload: true,
    });

    const filterDate = () => {
      switch (date) {
        case "testday": {
          doubleDate = false;
          return "2023-03-20T00:00:00.000Z";
        }
        case "today": {
          doubleDate = false;
          return moment().format("YYYY-MM-DD");
        }
        case "yesterday": {
          doubleDate = false;
          return moment()
            .subtract(1, "day")
            .startOf("day")
            .format("YYYY-MM-DD");
        }
        case "oneweek": {
          doubleDate = true;
          const from = moment().startOf("isoWeek").format("YYYY-MM-DD");
          const to = moment().endOf("isoWeek").format("YYYY-MM-DD");
          return {
            startDate: Math.floor(new Date(from).getTime() / 1000),
            endDate: Math.floor(new Date(to).getTime() / 1000),
          };
        }
        case "thismonth": {
          doubleDate = true;
          const from = moment().startOf("month").format("YYYY-MM-DD");
          const to = moment().endOf("month").format("YYYY-MM-DD");
          return {
            startDate: Math.floor(new Date(from).getTime() / 1000),
            endDate: Math.floor(new Date(to).getTime() / 1000),
          };
        }
        case "lastmonth": {
          doubleDate = true;
          const from = moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD");
          const to = moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");
          return {
            startDate: Math.floor(new Date(from).getTime() / 1000),
            endDate: Math.floor(new Date(to).getTime() / 1000),
          };
        }
        default:
          return null;
      }
    };

    //info about selected date + trigger doubleDate boolean
    console.log("[info] selected date: ", filterDate());

    if (doubleDate) {
      const table = {
        totalDataSize: 0,
        sizePerPage: 9999,
        currentPage: 1,
        filter: {
          created_at: {
            startDate: filterDate().startDate,
            endDate: filterDate().endDate,
          },
          status: {
            type: "TextFilter",
            value: 2,
            props: {},
          },
          subtype: "[TABLE] SET FILTER",
        },
        sort: {},
      };
      return dispatch(
        apiSagaRequest("TABLE_REQUEST", {
          method: "post",
          url: "/api/rewards/all/dates",
          data: { ...table },
        })
      ).then((response) => {
        //STOP LOADING
        dispatch({
          type: SET_LOADING,
          payload: false,
        });
        dispatch({
          type: GET_REQUESTS,
          payload: response.data.data,
        });
      });
    } else {
      const table = {
        totalDataSize: 0,
        sizePerPage: 9999,
        currentPage: 1,
        filter: {
          created_at: {
            value: {
              date: filterDate(),
              comparator: "=",
            },
            type: "DateFilter",
            props: {},
          },
          status: {
            type: "TextFilter",
            value: 2,
            props: {},
          },
          subtype: "[TABLE] SET FILTER",
        },
        sort: {},
      };
      return dispatch(
        apiSagaRequest("TABLE_REQUEST", {
          method: "post",
          url: "/api/rewards/all",
          data: { ...table },
        })
      ).then((response) => {
        //STOP LOADING
        dispatch({
          type: SET_LOADING,
          payload: false,
        });
        dispatch({
          type: GET_REQUESTS,
          payload: response.data.data,
        });
      });
    }
  };
}

export function setFraudalyticsTab(tabid) {
  return {
    type: SET_FRAUDALYTICS_TAB,
    payload: { tabid },
  };
}
