import React from "react";
import { authRoles } from "app/auth";

export const GameWhitelistConfig = {
  auth: [...authRoles.admin, ...authRoles.gamesViewer],
  routes: [
    {
      path: "/game-whitelist-competitors",
      component: React.lazy(() => import("./GameWhitelist")),
      name: "Game Whitelist Competitors",
    },
  ],
};
