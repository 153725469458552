import * as Actions from "./store/actions";
import * as Yup from "yup";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Formik } from "formik";
import React, { Component } from "react";

import { ISP_CONST } from "@tapchamps/Types";
import ReferalsTable from "app/views/Referals/ReferalsTable";
import { TapchampsUtils } from "@tapchamps";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

const tableName = "all";

const validationSchema = function (values) {
  return Yup.object().shape({
    name: Yup.string().required("ISP Name is required!"),
    value: Yup.string(),
  });
};

class Referals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAdd: false,
      filterWeek: false,
      filterDays: false,
    };

    this.filterDays = this.filterDays.bind(this);
  }
  componentDidMount() {
    //this.props.dispatch(Actions.getStats());
    this.props.fetch(tableName);
  }
  onSortChange(sortName, sortOrder) {
    this.props.setSort(
      tableName,
      sortName,
      sortOrder,
      this.state.filterWeek,
      this.state.filterDay
    );
  }
  onPageChange(page, sizePerPage) {
    this.props.setPaging(tableName, page, sizePerPage);
  }
  onSizePerPageList(sizePerPage) {}

  filterDay() {
    this.setState(
      {
        filterDay: !this.state.filterDay,
        filterWeek: false,
      },
      () => {
        this.props.setDayFilter(
          tableName,
          this.state.filterWeek,
          this.state.filterDay
        );
      }
    );
  }

  filterDays(x) {
    if (x === this.state.filterDays) {
      this.setState(
        {
          filterDays: 0,
        },
        () => {
          this.props.setDayFilter(tableName, this.state.filterDays);
        }
      );
    } else {
      this.setState(
        {
          filterDays: x,
        },
        () => {
          this.props.setDayFilter(tableName, this.state.filterDays);
        }
      );
    }
  }

  onFilterChange(filterObj) {
    this.props.setFilter(tableName, filterObj);
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  render() {
    const { loading, isTableLoading } = this.props;
    if (loading) {
      return loading();
    }

    const canViewUser = TapchampsUtils.hasPermission(
      ["admin", "referals-viewer"],
      this.props.user.role
    );

    const {
      data,
      table: { totalDataSize, sizePerPage, currentPage },
    } = this.props;

    return (
      <div className="animated fadeIn">
        {canViewUser && (
          <>
            <Row>
              <Col>
                <Card>
                  <CardHeader>Referals</CardHeader>
                  <CardBody>
                    {loading ? (
                      <div className="text-center">Loading data</div>
                    ) : false ? (
                      <div className="text-center">Empty</div>
                    ) : (
                      <ReferalsTable
                        onSortChange={this.onSortChange.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        onSizePerPageList={this.onSizePerPageList.bind(this)}
                        data={Object.values(data)}
                        loading={isTableLoading}
                        totalDataSize={totalDataSize}
                        sizePerPage={sizePerPage}
                        currentPage={currentPage}
                        role={this.props.user.role}
                        filterDays={this.state.filterDays}
                        filterDaysClick={this.filterDays}
                        onFilterChange={this.onFilterChange.bind(this)}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Formik
              initialValues={{ name: "" }}
              validationSchema={validationSchema}
              render={({
                values,
                errors,
                touched,
                status,
                dirty,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                handleReset,
                setFieldTouched,
                setFieldValue,
              }) => (
                <Modal isOpen={this.state.modalAdd} toggle={this.toggleAdd}>
                  <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={this.toggleAdd}>
                      Add ISP to table
                    </ModalHeader>
                    <ModalBody>
                      Enter ISP name (case sensitive):
                      <Input
                        id="name"
                        invalid={touched.name && !!errors.name}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <FormFeedback>{errors.name}</FormFeedback>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          setFieldValue("value", ISP_CONST.banned);
                        }}
                        type="submit"
                        color="danger"
                        disabled={isSubmitting}
                      >
                        Add as banned
                      </Button>
                      <Button
                        color="success"
                        onClick={() => {
                          setFieldValue("value", ISP_CONST.allowed);
                        }}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Add as allowed
                      </Button>
                      <Button
                        color="secondary"
                        onClick={this.toggleAdd}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>
              )}
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    table: state.isp.table[tableName].table,
    data: state.isp.table[tableName].data,
    isTableLoading: state.isp.table[tableName].loading,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default withReducer(
  "isp",
  reducer
)(connect(mapStateToProps, mapDispatchToProps)(Referals));
