import * as Actions from "./store/actions";

import RevenuesView from "app/views/Revenues/RevenuesView";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

function mapStateToProps(state) {
  return {
    filter: state.revenues.filter,
    revenues: state.revenues.revenues,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default withReducer(
  "revenues",
  reducer
)(connect(mapStateToProps, mapDispatchToProps)(RevenuesView));
