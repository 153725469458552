import React from "react";
import { authRoles } from "app/auth";
export const CampaignsConfig = {
  auth: [...authRoles.admin, ...authRoles.gamesViewer],
  routes: [
    {
      path: "/campaigns",
      component: React.lazy(() => import("./campaigns/Campaigns")),
      name: "Campaigns",
    },
  ],
};
