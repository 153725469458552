import React from "react";
import { authRoles } from "app/auth";

export const BoostsAndBonusesConfig = {
  auth: authRoles.admin,
  routes: [
    {
      path: "/extragamebonus",
      component: React.lazy(() => import("./pages/ExtraGameBonus")),
      name: "ExtraGameBonus",
    },
    {
      path: "/gameboost",
      component: React.lazy(() => import("./pages/GameBoost")),
      name: "GameBoost",
    },
  ],
};
